import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow, // Import InfoWindow
} from "@react-google-maps/api";
import Select from "react-select";
import firebase from "firebase/compat/app";
import "firebase/firestore";
import locationMarker from "../../assets/images/icons/location_marker.png";
import Flatpickr from "react-flatpickr"; // Import FlatPickr
import "flatpickr/dist/themes/material_blue.css"; // Import FlatPickr CSS (ensure this matches your existing theme)

/**
 * Ensure that you have installed the necessary packages:
 * npm install react-flatpickr flatpickr @react-google-maps/api
 */

const containerStyle = {
  width: "100%",
  height: "100%",
};

const defaultCenter = {
  lat: 37.7749, // Example: San Francisco
  lng: -122.4194,
};

const ExploreMap = ({ sidebarCollapsed }) => {
  // State Hooks
  const [allCatches, setAllCatches] = useState([]);
  const [filteredCatches, setFilteredCatches] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [speciesOptions, setSpeciesOptions] = useState([]);
  const [filters, setFilters] = useState({
    type: "all",
    species: "all",
    startDate: null, // Added startDate
    endDate: null,   // Added endDate
  });
  const [sortOption, setSortOption] = useState("catch_date");
  const [isScrollerVisible, setIsScrollerVisible] = useState(true);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerIconsLoaded, setMarkerIconsLoaded] = useState(false);
  const [selectedMarkerIcon, setSelectedMarkerIcon] = useState(null);
  const [defaultMarkerIcon, setDefaultMarkerIcon] = useState(null);
  const [infoWindowMarkerId, setInfoWindowMarkerId] = useState(null); // For InfoWindow

  const scrollerRef = useRef(null);
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null); // Define mapRef using useRef
  const autocompleteInitialized = useRef(false); // To track Autocomplete initialization

  // Google Maps API Key (Included directly as per user request)
  const GOOGLE_MAPS_API_KEY = "AIzaSyCcnZ45Qluty82SrlZkkjbFZFKFflYbiZQ"; // Replace with your actual API key

  // Load Google Maps API with 'places' and 'geometry' libraries
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["places", "geometry"], // Added 'geometry' for distance calculations
  });

  // Compute left position based on sidebarCollapsed
  const sidebarWidth = sidebarCollapsed ? 20 : 250;
  const leftPosition = sidebarWidth + 20; // 20px margin

  // Define marker icons after API is loaded
  useEffect(() => {
    if (isLoaded && window.google && window.google.maps) {
      const selectedIcon = {
        url: locationMarker,
        scaledSize: new window.google.maps.Size(64, 64),
      };
      const defaultIcon = {
        url: locationMarker,
        scaledSize: new window.google.maps.Size(48, 48),
      };
      setSelectedMarkerIcon(selectedIcon);
      setDefaultMarkerIcon(defaultIcon);
      setMarkerIconsLoaded(true);
    }
  }, [isLoaded]);

  // Inject CSS to hide the InfoWindow close button
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      /* Hide the InfoWindow close button */
      .gm-style-iw button.gm-ui-hover-effect {
        display: none !important;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // Fetch Data on Mount
  useEffect(() => {
    if (isLoaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  // Initialize Autocomplete once after data is fetched and markers are loaded
  useEffect(() => {
    if (isLoaded && markerIconsLoaded && !autocompleteInitialized.current) {
      initAutocomplete();
      autocompleteInitialized.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, markerIconsLoaded]);

  const fetchData = async () => {
    try {
      const programData = JSON.parse(localStorage.getItem("programData"));
      if (!programData || !programData.id) {
        console.error("Program data is missing or invalid.");
        return;
      }
      const programRef = firebase
        .firestore()
        .collection("programs")
        .doc(programData.id);

      // Fetch Species
      const speciesSnapshot = await firebase
        .firestore()
        .collection("species")
        .orderBy("name", "asc")
        .get();
      const species = [];
      const speciesMapping = {};

      speciesSnapshot.forEach((doc) => {
        const data = doc.data();
        species.push({ name: data.name, id: doc.id });
        speciesMapping[doc.id] = data.name;
      });

      // Transform species for react-select
      const speciesOptionsFormatted = [
        { value: "all", label: "All Species" },
      ];
      species.forEach((sp) => {
        speciesOptionsFormatted.push({ value: sp.id, label: sp.name });
      });

      setSpeciesOptions(speciesOptionsFormatted);

      // Fetch Catches
      firebase
        .firestore()
        .collection("catches")
        .where("tag_program", "==", programRef)
        .onSnapshot(
          (querySnapshot) => {
            const newCatches = [];
            querySnapshot.forEach((doc) => {
              const catchItem = doc.data();
              catchItem.id = doc.id;
              catchItem.name = catchItem.name || "Unknown Name";
              catchItem.location_string =
                catchItem.location_string || "Unknown Location";
              catchItem.image_url =
                catchItem.image_url || "https://firebasestorage.googleapis.com/v0/b/fish-trak-app.appspot.com/o/placeholders%2Ffish-trak-catch-placeholder.jpg?alt=media&token=62a05f7c-ec05-4c47-8996-799fe5fd3389";

              // Map species UID to species name
              catchItem.species_name =
                speciesMapping[catchItem.fish_species?.id] ||
                "Unknown Species";

              newCatches.push(catchItem);
            });
            setAllCatches(newCatches);
            setFilteredCatches(newCatches);

            // Set Initial Selection
            if (newCatches.length > 0 && !selectedMarker) {
              setSelectedMarker(newCatches[0].id);
              setMapCenter({
                lat: newCatches[0].location.latitude,
                lng: newCatches[0].location.longitude,
              });
            }
          },
          (error) => {
            console.error("Error fetching catches:", error);
          }
        );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Combined Filter and Sort Function
  const applyFiltersAndSort = () => {
    let filtered = [...allCatches];

    // Filter by Recatch Type
    if (filters.type === "new") {
      filtered = filtered.filter((item) => item.recatch === false);
    } else if (filters.type === "recatch") {
      filtered = filtered.filter((item) => item.recatch === true);
    }

    // Filter by Species
    if (filters.species !== "all") {
      filtered = filtered.filter(
        (item) => item.fish_species?.id === filters.species
      );
    }

    // Filter by Date Range
    if (filters.startDate && filters.endDate) {
      const start = new Date(filters.startDate).setHours(0, 0, 0, 0);
      const end = new Date(filters.endDate).setHours(23, 59, 59, 999);
      filtered = filtered.filter((item) => {
        const catchDate = new Date(item.catch_date).getTime();
        return catchDate >= start && catchDate <= end;
      });
    }

    // Sort
    if (sortOption === "name") {
      filtered.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption === "catch_date") {
      filtered.sort(
        (a, b) => new Date(b.catch_date) - new Date(a.catch_date)
      );
    } else if (sortOption === "nearby") {
      // Sort by distance to mapCenter
      if (mapRef.current) {
        const mapCenterLatLng = new window.google.maps.LatLng(
          mapCenter.lat,
          mapCenter.lng
        );
        filtered.sort((a, b) => {
          const aLatLng = new window.google.maps.LatLng(
            a.location.latitude,
            a.location.longitude
          );
          const bLatLng = new window.google.maps.LatLng(
            b.location.latitude,
            b.location.longitude
          );
          const distanceA = window.google.maps.geometry.spherical.computeDistanceBetween(
            mapCenterLatLng,
            aLatLng
          );
          const distanceB = window.google.maps.geometry.spherical.computeDistanceBetween(
            mapCenterLatLng,
            bLatLng
          );
          return distanceA - distanceB;
        });
      }
    }

    setFilteredCatches(filtered);

    // Retain the current selectedMarker if it's still in the filtered list
    if (selectedMarker && filtered.some(item => item.id === selectedMarker)) {
      // No change needed
    } else if (filtered.length > 0) {
      // If the current selectedMarker is not in the filtered list, select the first one
      setSelectedMarker(filtered[0].id);
      setMapCenter({
        lat: filtered[0].location.latitude,
        lng: filtered[0].location.longitude,
      });
    } else {
      setSelectedMarker(null);
    }
  };

  // Re-apply Filters and Sort when Dependencies Change
  useEffect(() => {
    applyFiltersAndSort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortOption, allCatches, mapCenter]);

  // Center Map on Selected Marker with Smooth Animation
  const centerMapOnMarker = (latitude, longitude) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat: latitude, lng: longitude });
      mapRef.current.setZoom(12); // Optionally, adjust zoom
    }
  };

  // Initialize Autocomplete
  const initAutocomplete = () => {
    if (
      !window.google ||
      !window.google.maps ||
      !window.google.maps.places
    ) {
      console.error("Google Maps Places library is not loaded.");
      return;
    }
    if (!autocompleteRef.current) {
      console.error("Autocomplete input reference is not available.");
      return;
    }
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["geocode"],
      }
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        centerMapOnMarker(location.lat(), location.lng());
      }
    });
  };

  // Scroll Functionality with Smooth Centering
  const scrollHorizontally = (direction) => {
    const scroller = scrollerRef.current;
    if (scroller) {
      const scrollAmount = 300;
      scroller.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const scrollToItem = (element) => {
    element.scrollIntoView({ behavior: "smooth", inline: "center" });
  };

  // Handle Marker Click to Center Map and Scroll Scroller
  const handleMarkerClick = (catchItem) => {
    setSelectedMarker(catchItem.id);
    setMapCenter({
      lat: catchItem.location.latitude,
      lng: catchItem.location.longitude,
    });
    centerMapOnMarker(catchItem.location.latitude, catchItem.location.longitude);

    // Open InfoWindow
    setInfoWindowMarkerId(catchItem.id);
  };

  // Handle Scroller Item Click to Center Map and Scroll Scroller
  const handleCardClick = (catchItem) => {
    setSelectedMarker(catchItem.id);
    setMapCenter({
      lat: catchItem.location.latitude,
      lng: catchItem.location.longitude,
    });
    centerMapOnMarker(catchItem.location.latitude, catchItem.location.longitude);

    // Close any open InfoWindow
    setInfoWindowMarkerId(null);
  };

  // Handle Species Filter Change
  const handleSpeciesChange = (selectedOption) => {
    setFilters({ ...filters, species: selectedOption.value });
  };

  // Handle Catch Type Filter Change
  const handleTypeChange = (e) => {
    setFilters({ ...filters, type: e.target.value });
  };

  // Handle Sort Option Change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  // Handle Start Date Change
  const handleStartDateChange = (selectedDates) => {
    setFilters({ ...filters, startDate: selectedDates[0] });
  };

  // Handle End Date Change
  const handleEndDateChange = (selectedDates) => {
    setFilters({ ...filters, endDate: selectedDates[0] });
  };

  // Handle Scroller Toggle
  const toggleScroller = () => {
    setIsScrollerVisible(!isScrollerVisible);
  };

  // Global Click Listener to Close InfoWindow When Clicking Outside Map and Filter Bar
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click is inside the map or filter bar or scroller, do nothing
      if (
        mapRef.current &&
        mapRef.current.getDiv().contains(event.target)
      ) {
        return;
      }
      const filterBar = document.getElementById("filterBar");
      const scroller = scrollerRef.current;
      if (
        filterBar &&
        filterBar.contains(event.target)
      ) {
        return;
      }
      if (
        scroller &&
        scroller.contains(event.target)
      ) {
        return;
      }
      // Otherwise, close the InfoWindow
      setInfoWindowMarkerId(null);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded || !markerIconsLoaded) {
    return <div>Loading Maps...</div>;
  }

  return (
    <div style={styles.wrapper}>
      {/* FILTER BAR */}
      <div id="filterBar" style={styles.filterBar}>
        {/* Catch Type Filter */}
        <div style={styles.filterItem}>
          <select
            id="catchType"
            style={styles.select}
            value={filters.type}
            onChange={handleTypeChange}
          >
            <option value="all">All Catches</option>
            <option value="new">1st Catches</option>
            <option value="recatch">Re-Catches</option>
          </select>
        </div>

        {/* Species Filter - Searchable */}
        <div style={styles.filterItem}>
          <Select
            id="speciesFilter"
            options={speciesOptions}
            defaultValue={{ value: "all", label: "All Species" }}
            onChange={handleSpeciesChange}
            styles={customSelectStyles}
            isSearchable
          />
        </div>

        {/* Start Date Filter */}
        <div style={styles.filterItem}>
          <Flatpickr
            id="startDate"
            options={{ dateFormat: "Y-m-d" }}
            value={filters.startDate}
            onChange={handleStartDateChange}
            style={styles.dateInput} // Apply smaller style
            placeholder="Start Date"
          />
        </div>

        {/* End Date Filter */}
        <div style={styles.filterItem}>
          <Flatpickr
            id="endDate"
            options={{ dateFormat: "Y-m-d" }}
            value={filters.endDate}
            onChange={handleEndDateChange}
            style={styles.dateInput} // Apply smaller style
            placeholder="End Date"
          />
        </div>

        {/* Location Search Box */}
        <div style={styles.filterItem}>
          <input
            ref={autocompleteRef}
            type="text"
            id="locationSearch"
            placeholder="Search Location..."
            style={styles.input}
          />
        </div>

        {/* Sort Option */}
        <div style={styles.filterItem}>
          <select
            id="sortOption"
            style={styles.select}
            value={sortOption}
            onChange={handleSortChange}
          >
            <option value="nearby">Sort by Nearby</option>
            <option value="name">Sort by Name</option>
            <option value="catch_date">Sort by Date</option>
          </select>
        </div>

        {/* Scroller Toggle Button */}
        <div style={{ ...styles.filterItem, marginLeft: 'auto' }}> {/* Align to right */}
          <button
            onClick={toggleScroller}
            style={styles.toggleScrollerButton}
            aria-label="Toggle Scroller"
          >
            {isScrollerVisible ? "–" : "+"}
          </button>
        </div>
      </div>

      {/* MAP */}
      <div style={styles.mapContainer}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={6}
          onLoad={(map) => (mapRef.current = map)}
          onClick={() => setInfoWindowMarkerId(null)} // Close InfoWindow on map click
          options={{
            zoomControl: true,
            disableDefaultUI: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.TOP_RIGHT,
            },
          }}
        >
          {filteredCatches.map((catchItem) => (
            <Marker
              key={catchItem.id}
              position={{
                lat: catchItem.location?.latitude || 0,
                lng: catchItem.location?.longitude || 0,
              }}
              icon={
                selectedMarker === catchItem.id && selectedMarkerIcon
                  ? selectedMarkerIcon
                  : defaultMarkerIcon
              }
              onClick={(e) => {
                e.domEvent.stopPropagation(); // Prevent map click
                handleMarkerClick(catchItem);
              }}
            >
              {/* InfoWindow for Marker */}
              {infoWindowMarkerId === catchItem.id && (
                <InfoWindow
                  position={{
                    lat: catchItem.location?.latitude || 0,
                    lng: catchItem.location?.longitude || 0,
                  }}
                >
                  <div style={styles.infoWindow}>
                    <h4>{catchItem.name}</h4>
                    <a
                      href={`../catch/details/${catchItem.id}`}
                      style={{
                        display: "inline-block",
                        marginTop: "5px",
                        padding: "5px 10px",
                        backgroundColor: "#4192C3",
                        color: "#fff",
                        borderRadius: "4px",
                        textDecoration: "none",
                        fontSize: "12px",
                      }}
                      title="View Details"
                    >
                      View Details
                    </a>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
        </GoogleMap>
      </div>

      {/* SCROLLER */}
      {isScrollerVisible && (
        <div style={styles.scrollerWrapper}>
          {/* Left Arrow Button */}
          <div
            style={styles.arrowButton}
            onClick={() => scrollHorizontally("left")}
            title="Scroll Left"
            aria-label="Scroll Left"
          >
            <i className="mdi mdi-transfer-left"></i>
          </div>

          {/* Scrollable Items */}
          <div ref={scrollerRef} style={styles.scroller}>
            {filteredCatches.length === 0 ? (
              <div style={styles.noResults}>
                No results found for the selected filters.
              </div>
            ) : (
              filteredCatches.map((catchItem) => (
                <div
                  key={catchItem.id}
                  id={`card-${catchItem.id}`}
                  style={{
                    ...styles.card,
                    ...(selectedMarker === catchItem.id
                      ? styles.selectedCard
                      : {}),
                  }}
                  onClick={() => handleCardClick(catchItem)}
                >
                  {/* Navigation Button */}
                  {selectedMarker === catchItem.id && (
                    <a
                      href={`../catch/details/${catchItem.id}`}
                      style={styles.detailButton}
                      title="View Details"
                    >
                      View Details
                    </a>
                  )}
                  <img
                    src={catchItem.image_url}
                    alt={catchItem.name}
                    style={styles.cardImage}
                  />
                  <div>
                    <h4 className="pt-0 mt-0 mb-0 pb-0">{catchItem.name}</h4>
                    <p className="pt-0 mt-0 mb-0 pb-0">{catchItem.species_name}</p>
                    <p className="pt-0 mt-0 mb-0 pb-0">{catchItem.location_string}</p>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* Right Arrow Button */}
          <div
            style={styles.arrowButton}
            onClick={() => scrollHorizontally("right")}
            title="Scroll Right"
            aria-label="Scroll Right"
          >
            <i className="mdi mdi-transfer-right"></i>
          </div>
        </div>
      )}
    </div>
  );
};

// Custom styles for react-select
const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 150,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

// Define Styles
const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Fill the viewport height
    width: "100%", // Fill the viewport width
    position: "relative",
    overflow: "hidden",
  },
  filterBar: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "10px 15px", // Adjusted padding for better alignment
    backgroundColor: "#fff",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
    zIndex: 1000,
    position: "absolute",
    top: "80px",
    left: "10px", // Will be overridden dynamically
    right: "60px",
    borderRadius: "10px",
  },
  filterItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
  },
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    color: "#333",
  },
  select: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "6px",
    fontSize: "14px",
    color: "#333",
    outline: "none",
    minWidth: "125px",
  },
  flatpickr: {
    padding: "6px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "14px",
    color: "#333",
    outline: "none",
    width: "auto",
  },
  input: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "6px",
    fontSize: "14px",
    color: "#333",
    outline: "none",
    width: "auto",
  },
  dateInput: { // New style for date inputs
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "6px",
    fontSize: "14px",
    color: "#333",
    outline: "none",
    width: "100px", // Adjusted width to fit yyyy/mm/dd
  },
  mapContainer: {
    flex: 1, // Take up remaining space
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0px", // 20px margin from left and right edges
    overflow: "hidden",
    paddingTop: 70,
    paddingBottom: 60,
  },
  scrollerWrapper: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 10,
    paddingBottom: 10,
    paddingTop: 10,
    backgroundColor: "#fff",
    boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    transition: "all 0.3s ease",
    borderRadius: "10px",
    position: "fixed",
    bottom: 0,
    margin: "auto",
    marginBottom: 70,
    marginLeft: 10,
    marginRight: 10,
    width: "auto",
    zIndex: 1000,
  },
  scroller: {
    display: "flex",
    overflowX: "auto",
    gap: "15px",
    flex: 1,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  },
  card: {
    flex: "0 0 300px",
    height: "250px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    padding: "10px",
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative", // For positioning the button
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  },
  selectedCard: {
    border: "2px solid #4192C3",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  detailButton: { // New style for the navigation button
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#4192C3",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "12px",
  },
  cardImage: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  arrowButton: {
    width: "40px",
    height: "40px",
    backgroundColor: "#4192C3",
    borderRadius: "50%",
    color: "#fff",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    flexShrink: 0,
    margin: "0 10px",
    transition: "background-color 0.3s ease",
  },
  toggleScrollerButton: {
    width: "40px",
    height: "40px",
    backgroundColor: "#4192C3",
    borderRadius: "50%",
    color: "#fff",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    flexShrink: 0,
  },
  noResults: {
    textAlign: "center",
    width: "100%",
    padding: "20px",
    fontSize: "16px",
    color: "#666",
  },
  infoWindow: { // Style for InfoWindow content
    fontSize: "14px",
    color: "#333",
  },
};

export default ExploreMap;