import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import List from 'list.js';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';

const AllTags = () => {
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allTagsList, setAllTagsList] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    
    // Action buttons
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);
    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [isDeletingUnused, setIsDeletingUnused] = useState(false);
    const [isDeletingUsed, setIsDeletingUsed] = useState(false);
    
    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);
    const [isRestoringUnused, setIsRestoringUnused] = useState(false);
    const [isRestoringUsed, setIsRestoringUsed] = useState(false);
    
    // New State for Multi-Select Deletion
    const [selectedTags, setSelectedTags] = useState([]);
    const [bulkDeleteModal, setBulkDeleteModal] = useState(false);
    const [isBulkDeleting, setIsBulkDeleting] = useState(false);
    
    document.title = "Fish Tags | Fish-Trak - Partner's Dashboard";

    const [modal_list, setmodal_list] = useState(false);
    function tog_list() {
        setmodal_list(!modal_list);
    }
    
    const toggleModal = () => {
        setmodal_list(!modal_list);
    }

    const [editModal, setEditModal] = useState(false);
    function toggleEditModal(tagId, tagIndex) {
        
        if(editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        }
        else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }
        
        setEditModal(!editModal);
    }
    
    const [deleteUsedModal, setDeleteUsedModal] = useState(false);
    function toggleDeleteUsedModal(tagId, tagIndex) {
        
        if(deleteUsedModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        }
        else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        
        setDeleteUsedModal(!deleteUsedModal);
        
    }
    
    const [deleteUnusedModal, setDeleteUnusedModal] = useState(false);
    function toggleDeleteUnusedModal(tagId, tagIndex) {
        
        if(deleteUnusedModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        }
        else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }
        
        setDeleteUnusedModal(!deleteUnusedModal);
        
    }
    
    const [restoreUsedModal, setRestoreUsedModal] = useState(false);
    function toggleRestoreUsedModal(tagId, tagIndex) {
        
        if(restoreUsedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        }
        else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        
        setRestoreUsedModal(!restoreUsedModal);
        
    }
    
    const [restoreUnusedModal, setRestoreUnusedModal] = useState(false);
    function toggleRestoreUnusedModal(tagId, tagIndex) {
        
        if(restoreUnusedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        }
        else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }
        
        setRestoreUnusedModal(!restoreUnusedModal);
        
    }
    
    const deleteUsedTag = () => {
        
        setIsDeletingUsed(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allTagsList[deleteTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch decrement user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      
                      if(catchItem.recatch) {                        
                        if(!catchItem.recatch_count || catchItem.recatch_count === 0 || catchItem.recatch_count === 1 || catchItem.recatch_count === '' || catchItem.recatch_count === null) {
                            userData['recatch_count'] = 0; 
                          }
                          else {
                              userData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }  
                      else {
                          if(!catchItem.catch_count || catchItem.catch_count === 0 || catchItem.catch_count === 1 || catchItem.catch_count === '' || catchItem.catch_count === null) {
                            userData['catch_count'] = 0; 
                          }
                          else {
                              userData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                          }
                      }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                                updated_at: serverTimestamp(),
                                deleted: true
                            })
                            .then(() => {
                                
                                console.log('Deleted tag');
                               
                            })
                            .catch((error) => {
                              setIsDeletingUsed(false);
                              alert('Could not delete catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsDeletingUsed(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        deleted: true
                    })
                    .then(() => {
                        
                        fetchAllFishTags();
                        console.log('The tag has been deleted successfully!');
                        setDeleteTagId('');
                        setDeleteTagIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsDeletingUsed(false);
                      alert('Could not delete fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
    
    const restoreUsedTag = () => {
        
        setIsRestoringUsed(true);
        
        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(allTagsList[restoreTagIndex].id);
        const catchesRef = firebase.firestore().collection('catches');
        
        //Get all catches using the same tag
        catchesRef
         .where('tag', '==', tagRef)
         .onSnapshot(
              querySnapshot => {
                  querySnapshot.forEach(doc => {
                      const catchItem = doc.data();
                      
                      //For each catch increment user catch/recatch count
                      const userData = {
                        updated_at: serverTimestamp(),
                      };
                      
                      if(catchItem.recatch) {                        
                        userData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                      }  
                      else {
                        userData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                      }
                      
                      usersRef
                      .doc(catchItem.user.id)
                      .update(userData)
                      .then((userResponse) => {
                          
                          //Delete the catch or recatch
                          catchesRef
                            .doc(doc.id)
                            .update({
                                updated_at: serverTimestamp(),
                                deleted: false
                            })
                            .then(() => {
                                
                                console.log('Restored tag');
                               
                            })
                            .catch((error) => {
                              setIsRestoringUsed(false);
                              alert('Could not restore catch entry. Please try again.');
                            });
                          
                        
                      })
                      .catch((error) => {
                        setIsRestoringUsed(false);
                        alert('Could not update user status. Please try again.');
                      }); 
                      
                  });
                  
                  tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        deleted: false
                    })
                    .then(() => {
                        
                        fetchAllFishTags();
                        console.log('The tag has been restored successfully!');
                        setRestoreTagId('');
                        setRestoreTagIndex(null);
                       
                    })
                    .catch((error) => {
                      setIsRestoringUsed(false);
                      alert('Could not restore fish tag entry. Please try again.');
                    });
                  
              },
              error => {
                  console.log(error);
              }
          );   
          
    }
    
    const deleteUnusedTag = async () => {
        
        setIsDeletingUnused(true);
                
        //Delete tag from database
        const tagsRef = firebase.firestore().collection('tags').doc(deleteTagId);  
        tagsRef
            .update({
              updated_at: serverTimestamp(),
              deleted: true
            })
            .then(() => {
              fetchAllFishTags();
              setDeleteTagId('');
              setDeleteTagIndex(null);
              alert('The fish tag had been deleted successfully!');
            })
            .catch((error) => {
              setIsDeletingUnused(false);
              alert('Could not delete tag entry. Please try again.');
            });
    };
    
    const restoreUnusedTag = async () => {
          
          setIsRestoringUnused(true);
                  
          //Delete tag from database
          const tagsRef = firebase.firestore().collection('tags').doc(restoreTagId);  
          tagsRef
            .update({
              updated_at: serverTimestamp(),
              deleted: false
            })
            .then(() => {
              fetchAllFishTags(); 
              setRestoreTagId('');
              setRestoreTagIndex(null);
              alert('The fish tag had been restored successfully!');
            })
            .catch((error) => {
              setIsRestoringUnused(false);
              alert('Could not restore tag entry. Please try again.');
            });
      };
    
    const submitUpdateForm = async (event) => {
                
        setIsUpdating(true);
        event.preventDefault();
        
        const programId = event.target.tag_program.value;
        const programIndex = event.target.tag_program.selectedIndex - 1;
        const userId = event.target.first_angler.value;
        const tagRef = firebase.firestore().collection('tags');
                 
        //Create array of catch data
        const tagData = {
          updated_at: serverTimestamp(),
        };
        
        if(event.target.tag_number.value !== allTagsList[updateTagIndex].tag) {
            tagData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(programId !== allTagsList[updateTagIndex].tag_program.id) {
            tagData['tag_program'] = firebase.firestore().collection('programs').doc(programId);
            tagData['program_image'] = allTagPrograms[programIndex].image;
            tagData['program_name'] = allTagPrograms[programIndex].name;
        }
        if(userId !== allTagsList[updateTagIndex].first_angler.id) {
            tagData['first_angler'] = firebase.firestore().collection('users').doc(userId);
        }
               
        //Update tag in firestore
        tagRef
            .doc(allTagsList[updateTagIndex].id)
            .update(tagData)
            .then((tagResponse) => {
              alert('The tag has been updated successfully!');
              fetchAllFishTags(); 
            })
            .catch((error) => {
              setIsUpdating(false);
              alert('Could not update tag entry. Please try again.');
            });  
    };
    
    const createList = () => {
         
        // Existing List
         const tagsListOptions = {
             valueNames: ['tag', 'program', 'created', 'updated', 'catch_count', 'status'],
             pagination: true,
             indexAsync: true,
             searchColumns: ['tag', 'program'],
             page: 10,
         }; 
         
         new List('tags-list', tagsListOptions);
    };
    
    const createNewFishTag = async (event) => {
        
        setIsUploading(true);
        event.preventDefault();
        
        const programIndex = event.target.tag_program.value;
        const userIndex = event.target.first_user.value;
        const tagRef = firebase.firestore().collection('tags');
                
      //Create array of catch data
        const tagData = {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
          tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
          catch_count: 0,
          used: false,
          deleted: false,
          program_image: allTagPrograms[programIndex].image,
          program_name: allTagPrograms[programIndex].name,
          tag_program: firebase.firestore().collection('programs').doc(allTagPrograms[programIndex].id),
          first_angler: firebase.firestore().collection('users').doc(allUsersList[userIndex].id),
        };
                
        //Create new tag in firestore
        tagRef
            .add(tagData)
            .then((tagResponse) => {
              alert('The new fish tag has been created successfully!');
              fetchAllFishTags(); 
            })
            .catch((error) => {
              setIsUploading(false);
              alert('Could not create fish tag entry. Please try again.');
            });  
    };
    
    const fetchTagPrograms = async () => {
          
     firebase
     .firestore()
     .collection('programs')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newProgramsList = []
              querySnapshot.forEach(doc => {
                  const programItem = doc.data();
                  programItem.id = doc.id;
                  
                  programItem.name = programItem.program_name;
                  programItem.region = programItem.program_region;
                  programItem.country = programItem.country_code;
                  programItem.type = programItem.program_type;
                  programItem.length = programItem.tag_length;
                  programItem.image = programItem.image_url;
                  
                  const createdAt = programItem && programItem.created_at && programItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.created = createdAt;
                    const updatedAt = programItem && programItem.updated_at && programItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                    programItem.updated = updatedAt;
                  
                  
                  newProgramsList.push(programItem);
              });
              setAllTagPrograms(newProgramsList);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchAllFishTags = () => {
          
     firebase
     .firestore()
     .collection('tags')
     .orderBy('created_at', 'desc')
     .onSnapshot(
          querySnapshot => {
              const newTagsList = []
              querySnapshot.forEach(doc => {
                  const tagItem = doc.data();
                  const tagId = doc.id;
                  tagItem.id = tagId;
                  
                  tagItem.tag = '#' + tagItem.tag_number;
                  tagItem.program = tagItem.program_name;
                  const createdAt = tagItem && tagItem.created_at && tagItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.created = createdAt;
                  const updatedAt = tagItem && tagItem.updated_at && tagItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                  tagItem.updated = updatedAt;
                  tagItem.link = 'tag/details/' + tagId;  
                  tagItem.program_link = 'program/details/' + tagItem.tag_program.id;  

                  newTagsList.push(tagItem);
              });
              setAllTagsList(newTagsList);
              setIsUploading(false);
              setmodal_list(false);
              setDeleteUsedModal(false);
              setDeleteUnusedModal(false);
              setFetchedData(true);
              setIsDeletingUnused(false);
              setIsDeletingUsed(false);
              setRestoreUsedModal(false);
              setRestoreUnusedModal(false);
              setIsRestoringUnused(false);
              setIsRestoringUsed(false);
              setEditModal(false);
              setIsUpdating(false);
          },
          error => {
              console.log(error);
          }
      )
    };
    
    const fetchAllUsers = async () => {
           
      firebase
      .firestore()
      .collection('users')
      .orderBy('created_at', 'desc')
      .onSnapshot(
           querySnapshot => {
               const newUsersList = []
               querySnapshot.forEach(doc => {
                   const userItem = doc.data();
                   userItem.id = doc.id;
                   
                   userItem.name = userItem.first_name + ' ' + userItem.last_name;
                   userItem.email = userItem.email_address;
                   userItem.port = userItem.home_port;
                   userItem.count = userItem.catch_count;
                   
                   const createdAt = userItem && userItem.created_at && userItem.created_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                     userItem.created = createdAt;
                     const updatedAt = userItem && userItem.updated_at && userItem.updated_at.toDate().toLocaleString('en-US', {year: 'numeric', month: 'short', day: 'numeric'});
                     userItem.updated = updatedAt;
                   
                   if(userItem.active === true) {
                     userItem.status = 'Active';
                   }
                   else {
                     userItem.status = 'Disabled';
                   }        
                   
                   newUsersList.push(userItem);
               });
               setAllUsersList(newUsersList);
               
           },
           error => {
               console.log(error)
           }
       )
     };

    useEffect(() => {

        if(initialLoad) {
           setInitialLoad(false);
           fetchAllFishTags();
           fetchTagPrograms();
           fetchAllUsers();
         }
         
         if(fetchedData && allTagsList && (allTagsList.length > 0)) {
              setFetchedData(false);
              createList();
            }  
        
    }, [initialLoad, fetchedData, allTagsList]);

    // Handler for selecting/deselecting a single tag
    const handleSelectTag = (tagId) => {
        setSelectedTags(prevSelected => {
            if (prevSelected.includes(tagId)) {
                return prevSelected.filter(id => id !== tagId);
            } else {
                return [...prevSelected, tagId];
            }
        });
    };

    // Handler for selecting/deselecting all tags
    const handleSelectAll = () => {
        if (selectedTags.length === allTagsList.length) {
            setSelectedTags([]);
        } else {
            const allTagIds = allTagsList.map(tag => tag.id);
            setSelectedTags(allTagIds);
        }
    };

    // Handler to toggle the bulk delete modal
    const toggleBulkDeleteModal = () => {
        setBulkDeleteModal(!bulkDeleteModal);
    };

    // Bulk Delete Function
    const deleteSelectedTags = async () => {
        setIsBulkDeleting(true);

        // Separate tags into used and unused
        const usedTags = allTagsList.filter(tag => selectedTags.includes(tag.id) && tag.used && !tag.deleted);
        const unusedTags = allTagsList.filter(tag => selectedTags.includes(tag.id) && !tag.used && !tag.deleted);

        // Function to delete a single used tag
        const deleteUsedTagBulk = async (tag, index) => {
            return new Promise((resolve, reject) => {
                const usersRef = firebase.firestore().collection('users');
                const tagRef = firebase.firestore().collection('tags').doc(tag.id);
                const catchesRef = firebase.firestore().collection('catches');
                
                // Get all catches using the same tag
                catchesRef
                 .where('tag', '==', tagRef)
                 .get()
                 .then(querySnapshot => {
                     const batch = firebase.firestore().batch();

                     querySnapshot.forEach(doc => {
                         const catchItem = doc.data();
                         
                         // For each catch, decrement user catch/recatch count
                         const userDocRef = usersRef.doc(catchItem.user.id);
                         if(catchItem.recatch) {                        
                             if(!catchItem.recatch_count || catchItem.recatch_count <= 1) {
                                 batch.update(userDocRef, { recatch_count: 0, updated_at: serverTimestamp() });
                             }
                             else {
                                 batch.update(userDocRef, { recatch_count: firebase.firestore.FieldValue.increment(-1), updated_at: serverTimestamp() });
                             }
                         }  
                         else {
                             if(!catchItem.catch_count || catchItem.catch_count <= 1) {
                                 batch.update(userDocRef, { catch_count: 0, updated_at: serverTimestamp() });
                             }
                             else {
                                 batch.update(userDocRef, { catch_count: firebase.firestore.FieldValue.increment(-1), updated_at: serverTimestamp() });
                             }
                         }

                         // Mark catch as deleted
                         batch.update(catchesRef.doc(doc.id), { updated_at: serverTimestamp(), deleted: true });
                     });

                     // Mark tag as deleted
                     batch.update(tagRef, { updated_at: serverTimestamp(), deleted: true });

                     // Commit batch
                     batch.commit()
                        .then(() => {
                            console.log(`Used Tag ${tag.id} deleted successfully.`);
                            resolve();
                        })
                        .catch(error => {
                            console.error(`Error deleting used tag ${tag.id}:`, error);
                            reject(error);
                        });
                 })
                 .catch(error => {
                     console.error(`Error fetching catches for tag ${tag.id}:`, error);
                     reject(error);
                 });
            });
        };

        // Function to delete a single unused tag
        const deleteUnusedTagBulk = async (tag, index) => {
            return new Promise((resolve, reject) => {
                const tagRef = firebase.firestore().collection('tags').doc(tag.id);
                tagRef
                    .update({
                        updated_at: serverTimestamp(),
                        deleted: true
                    })
                    .then(() => {
                        console.log(`Unused Tag ${tag.id} deleted successfully.`);
                        resolve();
                    })
                    .catch(error => {
                        console.error(`Error deleting unused tag ${tag.id}:`, error);
                        reject(error);
                    });
            });
        };

        // Process used tags
        const usedDeletionPromises = usedTags.map((tag, index) => deleteUsedTagBulk(tag, index));

        // Process unused tags
        const unusedDeletionPromises = unusedTags.map((tag, index) => deleteUnusedTagBulk(tag, index));

        try {
            await Promise.all([...usedDeletionPromises, ...unusedDeletionPromises]);
            alert('Selected tags have been deleted successfully!');
            setSelectedTags([]);
            toggleBulkDeleteModal();
            fetchAllFishTags();
        } catch (error) {
            console.error('Error deleting selected tags:', error);
            alert('Could not delete some of the selected tags. Please try again.');
        } finally {
            setIsBulkDeleting(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="tags-list">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => tog_list()} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Tag
                                                    </Button>
                                                    {/* New Bulk Delete Button */}
                                                    <Button 
                                                        color="danger" 
                                                        className="add-btn" 
                                                        onClick={toggleBulkDeleteModal} 
                                                        disabled={selectedTags.length === 0}
                                                    >
                                                        <i className="ri-delete-bin-5-line align-bottom me-1"></i> Delete Selected
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="search" className="fuzzy-search form-control" placeholder="Search Tags..." />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeletingUsed && !isDeletingUnused &&
                                            <table className="table align-middle table-nowrap" id="tags-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* New Checkbox for Select All */}
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <Input 
                                                                type="checkbox" 
                                                                onChange={handleSelectAll} 
                                                                checked={selectedTags.length === allTagsList.length && allTagsList.length > 0}
                                                            />
                                                        </th>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th className="sort" data-sort="tag">Fish Tag Number</th>
                                                        <th className="sort" data-sort="program">Program Name</th>
                                                        <th className="sort" data-sort="created" data-default-order='desc'>Created Date</th>
                                                        <th className="sort" data-sort="updated" data-default-order='desc'>Updated Date</th>
                                                        <th className="sort" data-sort="catch_count">Catches</th>
                                                        <th className="sort" data-sort="status">Status</th>
                                                        <th className="sort" data-sort="action">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                {(allTagsList.length > 0) && allTagsList.map((tag, index) => ( 
                                                    <tr key={index}>
                                                        {/* New Checkbox for Selecting Individual Tags */}
                                                        <td>
                                                            <Input 
                                                                type="checkbox" 
                                                                checked={selectedTags.includes(tag.id)}
                                                                onChange={() => handleSelectTag(tag.id)}
                                                            />
                                                        </td>
                                                        <th scope="row">
                                                            <a
                                                                className="btn btn-sm btn-success edit-item-btn"
                                                                href={tag.link}
                                                                title="View Tag Details"
                                                            >
                                                                <i className="mdi mdi-eye-outline"></i>
                                                            </a>
                                                        </th>
                                                        <td className="tag">{tag.tag}</td>
                                                        <td>
                                                            <a 
                                                                href={tag.program_link} 
                                                                className="program fw-medium link-primary"
                                                                title="View Program Details"
                                                            >
                                                                {tag.program}
                                                            </a>
                                                        </td>
                                                        <td className="created">{tag.created}</td>
                                                        <td className="updated">{tag.updated}</td>
                                                        <td>
                                                            <span className="catch_count badge badge-soft-success text-uppercase">
                                                                {tag.catch_count}
                                                            </span>
                                                        </td>
                                                        
                                                        <td className="used">
                                                        {tag.used &&
                                                            <span className="badge badge-soft-success text-uppercase">Used</span>
                                                        }
                                                        {!tag.used &&
                                                            <span className="badge badge-soft-danger text-uppercase">Un-Used</span>
                                                        }
                                                        </td>
                                                        
                                                        <td className="action">
                                                            <div className="d-flex gap-2">
                                                                {/* Edit Button */}
                                                                <div className="edit">
                                                                    <button 
                                                                        className="btn btn-sm btn-warning edit-item-btn" 
                                                                        onClick={() => toggleEditModal(tag.id, index)}
                                                                        title="Edit Tag"
                                                                    >
                                                                        <i className="mdi mdi-square-edit-outline"></i>
                                                                    </button>
                                                                </div>
                                                                {/* Delete Button */}
                                                                {!tag.used && !tag.deleted &&
                                                                <div className="remove">
                                                                    <button 
                                                                        className="btn btn-sm btn-danger remove-item-btn" 
                                                                        onClick={() => toggleDeleteUnusedModal(tag.id, index)}
                                                                        title="Delete Unused Tag"
                                                                    >
                                                                        <i className="mdi mdi-delete-outline"></i>
                                                                    </button>
                                                                </div>
                                                                }
                                                                {tag.used && !tag.deleted &&
                                                                <div className="remove">
                                                                    <button 
                                                                        className="btn btn-sm btn-danger remove-item-btn" 
                                                                        onClick={() => toggleDeleteUsedModal(tag.id, index)}
                                                                        title="Delete Used Tag"
                                                                    >
                                                                        <i className="mdi mdi-delete-outline"></i>
                                                                    </button>
                                                                </div>
                                                                }
                                                                {!tag.used && tag.deleted &&
                                                                <div className="remove">
                                                                    <button 
                                                                        className="btn btn-sm btn-success remove-item-btn" 
                                                                        onClick={() => toggleRestoreUnusedModal(tag.id, index)}
                                                                        title="Restore Unused Tag"
                                                                    >
                                                                        <i className="mdi mdi-history"></i>
                                                                    </button>
                                                                </div>
                                                                }
                                                                {tag.used && tag.deleted &&
                                                                <div className="remove">
                                                                    <button 
                                                                        className="btn btn-sm btn-success remove-item-btn" 
                                                                        onClick={() => toggleRestoreUsedModal(tag.id, index)}
                                                                        title="Restore Used Tag"
                                                                    >
                                                                        <i className="mdi mdi-history"></i>
                                                                    </button>
                                                                </div>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </tbody>
                                            </table>}
                                            
                                            {allTagsList.length === 0 &&
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                            }
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center mt-3">
                                            {/* Pagination Controls */}
                                            <div className="pagination-wrap hstack gap-2">
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                            </div>
                                            {/* Show Selected Count */}
                                            {selectedTags.length > 0 && (
                                                <div className="text-muted">
                                                    {selectedTags.length} tag(s) selected
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    

                    
                </Container>
            </div>
            
            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => { toggleEditModal(); }} centered >
                <ModalHeader className="bg-light p-3" id="editModal" toggle={() => { toggleEditModal(); }}> Edit Tag Program </ModalHeader>
                
                {!isUpdating && allTagsList[updateTagIndex] &&
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>
                    
                        <div className="mb-3">
                            <label htmlFor="tagnumber-field" className="form-label">Tag Number*</label>
                            <input 
                                type="text" 
                                id="tagnumber-field" 
                                className="form-control" 
                                placeholder="#ABCDE1234567890" 
                                maxLength={15} 
                                required 
                                name="tag_number" 
                                defaultValue={allTagsList[updateTagIndex].tag}
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select 
                                className="form-control" 
                                data-trigger 
                                id="program-field" 
                                name="tag_program" 
                                required 
                                defaultValue={allTagsList[updateTagIndex].tag_program.id}
                            >
                                <option value="">Select Tag Program</option>
                                {allTagPrograms.map(({ name, id }, index) => (
                                    <option value={id} key={index}>{name}</option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Assigned User*</label>
                            <select 
                                className="form-control" 
                                data-trigger  
                                id="program-field" 
                                name="first_angler" 
                                required 
                                defaultValue={allTagsList[updateTagIndex].first_angler.id}
                            >
                                <option value="">Select User Email</option>
                                {allUsersList.map(({ email, id }, index) => (
                                    <option value={id} key={index}>{email}</option>
                                ))}
                            </select>
                        </div>
  
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => toggleEditModal()}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Update</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                {isUpdating && (
                    <ModalBody>
                        <ThreeCircles
                          visible={isUpdating}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{ style: "margin: 0px auto" }}
                          wrapperClass="justify-content-center"
                        />
                    </ModalBody>
                )}
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={modal_list} toggle={() => { tog_list(); }} centered >
                <ModalHeader className="bg-light p-3" id="exampleModalLabel" toggle={() => { tog_list(); }}> Add Fish Tag </ModalHeader>
                
                {!isUploading &&
                <form className="tablelist-form" onSubmit={createNewFishTag}>
                    <ModalBody>
                        <div className="mb-3">
                            <label htmlFor="tagnumber-field" className="form-label">Tag Number*</label>
                            <input 
                                type="text" 
                                id="tagnumber-field" 
                                className="form-control" 
                                placeholder="#ABCDE1234567890" 
                                maxLength={15} 
                                required 
                                name="tag_number"
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                            <select 
                                className="form-control" 
                                data-trigger 
                                id="program-field" 
                                name="tag_program" 
                                required
                            >
                                <option value="">Select Tag Program</option>
                                {allTagPrograms.map(({ name }, index) => (
                                    <option value={index} key={index}>{name}</option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="program-field" className="form-label">Assigned User*</label>
                            <select 
                                className="form-control" 
                                data-trigger  
                                id="program-field" 
                                name="first_user" 
                                required
                            >
                                <option value="">Select User Email</option>
                                {allUsersList.map(({ email }, index) => (
                                    <option value={index} key={index}>{email}</option>
                                ))}
                            </select>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn btn-light" onClick={() => setmodal_list(false)}>Cancel</button>
                            <button type="submit" className="btn btn-success" id="add-btn">Add Tag</button>
                        </div>
                    </ModalFooter>
                </form>
                }
                
                {isUploading && (
                    <ModalBody>
                        <ThreeCircles
                          visible={isUploading}
                          height="100"
                          width="100"
                          color="#4192C3"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{ style: "margin: 0px auto" }}
                          wrapperClass="justify-content-center"
                        />
                    </ModalBody>
                )}
                
            </Modal>

            {/* Remove Unused Tag Modal */}
            <Modal isOpen={deleteUnusedModal} toggle={() => { toggleDeleteUnusedModal(); }} className="modal zoomIn" id="deleteRecordModal" centered >
            
                {!isDeletingUnused &&
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Delete Un-Used Fish Tag</h4>
                            <p className="text-muted mx-4 mb-0">Are you sure you want to delete this fish tag?</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteUnusedModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteUnusedTag}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    {isDeletingUnused && (
                        <ModalBody>
                            <ThreeCircles
                              visible={isDeletingUnused}
                              height="100"
                              width="100"
                              color="#ff3d60"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ style: "margin: 0px auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </ModalBody>
                    )}
            </Modal>
            
            {/* Remove Used Tag Modal */}
            <Modal isOpen={deleteUsedModal} toggle={() => { toggleDeleteUsedModal(); }} className="modal zoomIn" id="deleteCatchModal" centered >
            
                {!isDeletingUsed &&
                <ModalBody>
                    <div className="mt-2 text-center">
                                <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                    <h4>Delete Used Fish Tag </h4><br/>
                                    <h5 className="text-muted mx-4 mb-0">This will delete the tag along with connected catches or recatches!!!</h5><br/>
                                    <p className="text-muted mx-4 mb-0">Are you sure you want to delete this tag?</p>
                                </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" className="btn w-sm btn-light" onClick={() => toggleDeleteUsedModal()}>Cancel</button>
                        <button type="button" className="btn w-sm btn-danger" id="delete-record" onClick={deleteUsedTag}>Delete</button>
                    </div>
                </ModalBody>
                }
                    
                    
                    {isDeletingUsed && (
                        <ModalBody>
                            <ThreeCircles
                              visible={isDeletingUsed}
                              height="100"
                              width="100"
                              color="#ff3d60"
                              ariaLabel="three-circles-loading"
                              wrapperStyle={{ style: "margin: 0px auto" }}
                              wrapperClass="justify-content-center"
                            />
                        </ModalBody>
                    )}
            </Modal>
            
            {/* Restore Unused Tag Modal */}
              <Modal isOpen={restoreUnusedModal} toggle={() => { toggleRestoreUnusedModal(); }} className="modal zoomIn" id="restoreUnusedModal" centered >
              
                  {!isRestoringUnused &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                              <h4>Restore Un-Used Fish Tag</h4>
                              <p className="text-muted mx-4 mb-0">Are you sure you want to restore this tag?</p>
                          </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreUnusedModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreUnusedTag}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      {isRestoringUnused && (
                          <ModalBody>
                              <ThreeCircles
                                visible={isRestoringUnused}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ style: "margin: 0px auto" }}
                                wrapperClass="justify-content-center"
                              />
                          </ModalBody>
                      )}
              </Modal>
              
              {/* Restore Used Tag Modal */}
              <Modal isOpen={restoreUsedModal} toggle={() => { toggleRestoreUsedModal(); }} className="modal zoomIn" id="restoreUsedModal" centered >
              
                  {!isRestoringUsed &&
                  <ModalBody>
                      <div className="mt-2 text-center">
                                  <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                      <h4>Restore Used Fish Tag </h4><br/>
                                      <h5 className="text-muted mx-4 mb-0">This will restore the tag along with connected catches or recatches!!!</h5><br/>
                                      <p className="text-muted mx-4 mb-0">Are you sure you want to restore this tag?</p>
                                  </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                          <button type="button" className="btn w-sm btn-light" onClick={() => toggleRestoreUsedModal()}>Cancel</button>
                          <button type="button" className="btn w-sm btn-success" id="restore-record" onClick={restoreUsedTag}>Restore</button>
                      </div>
                  </ModalBody>
                  }
                      
                      
                      {isRestoringUsed && (
                          <ModalBody>
                              <ThreeCircles
                                visible={isRestoringUsed}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ style: "margin: 0px auto" }}
                                wrapperClass="justify-content-center"
                              />
                          </ModalBody>
                      )}
              </Modal>

            {/* Bulk Delete Confirmation Modal */}
            <Modal isOpen={bulkDeleteModal} toggle={toggleBulkDeleteModal} className="modal zoomIn" id="bulkDeleteModal" centered >
                {!isBulkDeleting && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Selected Fish Tags</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to delete the selected fish tags?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <button type="button" className="btn w-sm btn-light" onClick={toggleBulkDeleteModal}>Cancel</button>
                            <button type="button" className="btn w-sm btn-danger" id="bulk-delete-record" onClick={deleteSelectedTags}>Delete Selected</button>
                        </div>
                    </ModalBody>
                )}
                
                {isBulkDeleting && (
                    <ModalBody>
                        <ThreeCircles
                          visible={isBulkDeleting}
                          height="100"
                          width="100"
                          color="#ff3d60"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{ style: "margin: 0px auto" }}
                          wrapperClass="justify-content-center"
                        />
                    </ModalBody>
                )}
            </Modal>
            
        </React.Fragment>
    );
};

export default AllTags;