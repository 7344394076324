import React, { useState, useEffect } from 'react';

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Draft.js and draftjs-to-html for content conversion
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Firebase imports
import firebase from "firebase/compat/app";
import "firebase/firestore";

// Reactstrap Components
import {
    Form,
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Button,
    Modal,
    ModalBody,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    CardTitle,
    CardHeader
} from "reactstrap";

// React Toastify Imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Breadcrumb (if needed)
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Questions = () => {
    document.title = "Questionnaire | Fish-Trak - Partner's Dashboard";

    // State Hooks for Dropdowns (Unchanged)
    const [folderbtn, setfolderbtn] = useState(false);
    const [tagbtn, settagbtn] = useState(false);
    const [menubtn, setmenubtn] = useState(false);

    // New State Hooks for Form Inputs
    const [senderEmail, setSenderEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // State Hook for Loading Modal
    const [isSaving, setIsSaving] = useState(false);

    // State Hooks for Templates
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('new'); // 'new' indicates creating a new template

    // State Hook for Dropdown Toggle
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    // State Hook for Manage Templates Modal
    const [isManageModalOpen, setIsManageModalOpen] = useState(false);
    const toggleManageModal = () => setIsManageModalOpen(prev => !prev);

    // Retrieve programData from localStorage
    const programObject = JSON.parse(localStorage.getItem("programData"));
    const programRef = firebase.firestore().collection('programs').doc(programObject.id);

    // Fetch Templates on Mount and when programRef changes
    useEffect(() => {
        const unsubscribe = firebase.firestore().collection("templates")
            .where("programRef", "==", programRef)
            .where("questions", "==", true)
            .orderBy("created_at", "desc")
            .onSnapshot(
                (querySnapshot) => {
                    const fetchedTemplates = [];
                    querySnapshot.forEach((doc) => {
                        fetchedTemplates.push({ id: doc.id, ...doc.data() });
                    });
                    setTemplates(fetchedTemplates);
                },
                (error) => {
                    console.error("Error fetching templates:", error);
                    toast.error("Failed to fetch templates.");
                }
            );

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [programRef]);

    // Handle Save Button Click
    const handleSave = async () => {
        // Basic Validation
        if (!senderEmail.trim()) {
            toast.error("Sender email is required.");
            return;
        }
        if (!subject.trim()) {
            toast.error("Subject is required.");
            return;
        }
        const contentState = convertToRaw(editorState.getCurrentContent());
        const convertedHtml = draftToHtml(contentState);
        if (!convertedHtml.trim()) {
            toast.error("Email content cannot be empty.");
            return;
        }

        setIsSaving(true);
        try {
            if (selectedTemplateId === 'new') {
                // Check if there's already an active template
                const activeTemplatesSnapshot = await firebase.firestore().collection("templates")
                    .where("programRef", "==", programRef)
                    .where("active", "==", true)
                    .where("questions", "==", true)
                    .get();

                if (activeTemplatesSnapshot.empty) {
                    // No active template exists, set this one as active
                    await firebase.firestore().collection("templates").add({
                        senderEmail: senderEmail,
                        subject: subject,
                        html: convertedHtml,
                        created_at: firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                        last_sent: null,
                        active: true,
                        deleted: false,
                        questions: true,
                        programRef: programRef,
                    });
                } else {
                    // Active template exists, do not set this as active
                    await firebase.firestore().collection("templates").add({
                        senderEmail: senderEmail,
                        subject: subject,
                        html: convertedHtml,
                        created_at: firebase.firestore.FieldValue.serverTimestamp(),
                        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                        last_sent: null,
                        active: false,
                        deleted: false,
                        questions: true,
                        programRef: programRef,
                    });
                }

                // Show Success Toast
                toast.success("Template saved successfully!");

                // Reset the form to create a new template
                setSelectedTemplateId('new');
                handleClear();
            } else {
                // Update an existing template
                await firebase.firestore().collection("templates").doc(selectedTemplateId).update({
                    senderEmail: senderEmail,
                    subject: subject,
                    html: convertedHtml,
                    updated_at: firebase.firestore.FieldValue.serverTimestamp(),
                });

                // Show Success Toast
                toast.success("Template updated successfully!");
            }
        } catch (error) {
            console.error("Error saving template: ", error);
            toast.error("Failed to save template. Please try again.");
        } finally {
            setIsSaving(false);
        }
    };

    // Handle Clear Button Click
    const handleClear = () => {
        setSenderEmail('');
        setSubject('');
        setEditorState(EditorState.createEmpty());
        setSelectedTemplateId('new');
    };

    // Handle Template Selection
    const handleSelectTemplate = (template) => {
        if (template.id === 'new') {
            // Selecting 'New Template'
            handleClear();
        } else {
            // Load selected template into the form
            setSelectedTemplateId(template.id);
            setSenderEmail(template.senderEmail || '');
            setSubject(template.subject || '');

            // Convert HTML to EditorState
            const html = template.html || '';
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            } else {
                setEditorState(EditorState.createEmpty());
            }
        }
    };

    // Handle Setting a Template as Active
    const handleSetActive = async (templateId) => {
        try {
            // Begin batch operation
            const batch = firebase.firestore().batch();

            // Get all active templates and set them to inactive
            const activeTemplatesSnapshot = await firebase.firestore().collection("templates")
                .where("programRef", "==", programRef)
                .where("questions", "==", true)
                .where("active", "==", true)
                .get();

            activeTemplatesSnapshot.forEach(doc => {
                batch.update(doc.ref, { active: false });
            });

            // Set the selected template as active
            const templateRef = firebase.firestore().collection("templates").doc(templateId);
            batch.update(templateRef, { active: true });

            // Commit the batch
            await batch.commit();

            toast.success("Template set as active successfully!");
        } catch (error) {
            console.error("Error setting active template:", error);
            toast.error("Failed to set active template.");
        }
    };

    // Handle Deleting a Template (Soft Delete)
    const handleDeleteTemplate = async (templateId) => {
        try {
            await firebase.firestore().collection("templates").doc(templateId).update({
                deleted: true,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            });

            toast.success("Template deleted successfully!");

            // If the deleted template was active, set another as active
            const deletedTemplate = templates.find(t => t.id === templateId);
            if (deletedTemplate && deletedTemplate.active) {
                // Find the next available template to set as active
                const nextTemplate = templates.find(t => t.id !== templateId && !t.deleted);
                if (nextTemplate) {
                    await handleSetActive(nextTemplate.id);
                }
            }
        } catch (error) {
            console.error("Error deleting template:", error);
            toast.error("Failed to delete template.");
        }
    };

    // Handle Restoring a Template
    const handleRestoreTemplate = async (templateId) => {
        try {
            await firebase.firestore().collection("templates").doc(templateId).update({
                deleted: false,
                updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            });

            toast.success("Template restored successfully!");
        } catch (error) {
            console.error("Error restoring template:", error);
            toast.error("Failed to restore template.");
        }
    };

    return (
        <React.Fragment>
            {/* Toast Container */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {/* Loading Spinner Modal */}
            <Modal isOpen={isSaving} backdrop="static" keyboard={false} centered>
                <ModalBody className="text-center">
                    <Spinner color="primary" />
                    <div className="mt-3">Saving Template...</div>
                </ModalBody>
            </Modal>

            {/* Manage Templates Modal */}
            <Modal isOpen={isManageModalOpen} toggle={toggleManageModal} size="lg">
                <Card>
                    <CardHeader>
                        <h5 className="mb-0">Manage Templates</h5>
                    </CardHeader>
                    <CardBody>
                        {templates.length === 0 ? (
                            <p>No templates available.</p>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Subject</th>
                                            <th>Active</th>
                                            <th>Deleted</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {templates.map(template => (
                                            <tr key={template.id}>
                                                <td>{template.subject}</td>
                                                <td>{template.active ? "Yes" : "No"}</td>
                                                <td>{template.deleted ? "Yes" : "No"}</td>
                                                <td>
                                                    {!template.deleted && (
                                                        <>
                                                            {!template.active && (
                                                                <Button
                                                                    color="success"
                                                                    size="sm"
                                                                    className="me-2"
                                                                    onClick={() => handleSetActive(template.id)}
                                                                >
                                                                    Set Active
                                                                </Button>
                                                            )}
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() => handleDeleteTemplate(template.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </>
                                                    )}
                                                    {template.deleted && (
                                                        <Button
                                                            color="warning"
                                                            size="sm"
                                                            onClick={() => handleRestoreTemplate(template.id)}
                                                        >
                                                            Restore
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardBody>
                </Card>
            </Modal>

            <div className="page-content">
                <Container fluid={true}>
                    {/* You can include Breadcrumbs here if needed */}
                    {/* <Breadcrumbs title="Email" breadcrumbItem="Instructions" /> */}

                    <CardTitle>Notifications</CardTitle>
                    <Row className="mb-4">
                        <Col xl={12}>
                            <Card className="mb-0">
                                <CardHeader className="d-flex justify-content-between align-items-center">
                                    <h4 className="card-title mb-0">Questionnaire Email</h4>
                                    <Button color="secondary" size="sm" onClick={toggleManageModal}>
                                        Manage Templates
                                    </Button>
                                </CardHeader>
                                
                                <CardBody>
                                    <div className="mb-0">
                                        {/* Template Selection Dropdown */}
                                        <div className="mb-3">
                                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                                <DropdownToggle caret color="secondary">
                                                    {selectedTemplateId === 'new' ? "New Template" : templates.find(t => t.id === selectedTemplateId)?.subject || "Edit Template"}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem header>Templates</DropdownItem>
                                                    <DropdownItem onClick={() => handleSelectTemplate({ id: 'new' })}>
                                                        New Template
                                                    </DropdownItem>
                                                    {templates.filter(t => !t.deleted).map((template) => (
                                                        <DropdownItem key={template.id} onClick={() => handleSelectTemplate(template)}>
                                                            {template.subject || "Untitled Template"}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>

                                        {/* Sender Email Input */}
                                        <div className="mb-3">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Sender Email"
                                                value={senderEmail}
                                                onChange={(e) => setSenderEmail(e.target.value)}
                                            />
                                        </div>

                                        {/* Subject Input */}
                                        <div className="mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Subject"
                                                value={subject}
                                                onChange={(e) => setSubject(e.target.value)}
                                            />
                                        </div>

                                        {/* Email Editor */}
                                        <div id="email-editor" style={{ minHeight: "360px" }}>
                                            <Form method="post">
                                                <Editor
                                                    editorState={editorState}
                                                    onEditorStateChange={setEditorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    placeholder="Enter Catch Questionnaire..."
                                                    toolbar={{
                                                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
                                                        inline: {
                                                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                                                        },
                                                        blockType: {
                                                            inDropdown: true,
                                                            options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
                                                        },
                                                        list: {
                                                            options: ['unordered', 'ordered'],
                                                        },
                                                        textAlign: {
                                                            options: ['left', 'center', 'right', 'justify'],
                                                        },
                                                        link: {
                                                            options: ['link'],
                                                        },
                                                    }}
                                                />
                                            </Form>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="btn-toolbar mt-3">
                                            <div className="">
                                                <Button
                                                    type="button"
                                                    color="danger"
                                                    className="waves-effect waves-light me-1"
                                                    onClick={handleClear}
                                                >
                                                    <span>Clear</span> <i className="far fa-trash-alt"></i>
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="waves-effect waves-light"
                                                    onClick={handleSave}
                                                    disabled={isSaving}
                                                >
                                                    <span>Save</span> <i className="fa fa-save ms-2"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

};

export default Questions;