import React, { useRef, useState, useEffect } from 'react';
import { 
    Button, 
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader, 
    Col, 
    Container, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    Row, 
    ModalHeader 
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const NewCatches = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [newCatchesLedger, setNewCatchesLedger] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    
    // New Filter States (Species, Date Range)
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    
    // Action Buttons States
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [updateTagProgramId, setUpdateTagProgramId] = useState('');
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoringCatch, setIsRestoringCatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    // CSV Upload States
    const [csvFile, setCsvFile] = useState(null);
    const [isCsvUploading, setIsCsvUploading] = useState(false);
    
    // Session Data References
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const programData = JSON.parse(localStorage.getItem("programData")) || {};
    
    // Pagination States
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const catchesPerPage = 10;
    
    // Firestore Listener Reference
    const subscriptionRef = useRef(null);
    
    // Set Document Title
    useEffect(() => {
        document.title = "Catch Ledger | Fish-Trak - Partner's Dashboard";
    }, []);

    // Modal State Handlers
    const [modalList, setModalList] = useState(false);
    const toggleModalList = () => setModalList(!modalList);
    
    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = (catchId = '', catchIndex = null) => {
        if (deleteModal) {
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } else {
            setDeleteCatchId(catchId);
            setDeleteCatchIndex(catchIndex);
        }
        setDeleteModal(!deleteModal);
    };
    
    const [restoreCatchModalState, setRestoreCatchModalState] = useState(false);
    const toggleRestoreCatchModal = (catchId = '', catchIndex = null) => {
        if (restoreCatchModalState) {
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } else {
            setRestoreCatchId(catchId);
            setRestoreCatchIndex(catchIndex);
        }
        setRestoreCatchModalState(!restoreCatchModalState);
    };
    
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = (catchId = '', catchIndex = null) => {
        if (editModal) {
            setUpdateCatchId('');
            setUpdateCatchIndex(null);
        } else {
            if (catchId && catchIndex !== null) {
                getUpdateTag(catchId, catchIndex);
                setUpdateCatchId(catchId);
                setUpdateCatchIndex(catchIndex);
            }
        }
        setEditModal(!editModal);
    };

    // Fetch All Species
    const fetchAllSpecies = () => {
        firebase.firestore().collection('species').orderBy('name', 'asc')
            .onSnapshot(
                querySnapshot => {
                    const speciesList = [];
                    querySnapshot.forEach(doc => {
                        const speciesItem = doc.data();
                        speciesItem.id = doc.id;
                        speciesList.push(speciesItem);
                    });
                    setAllSpecies(speciesList);
                },
                error => {
                    console.error("Error fetching species:", error);
                }
            );
    };

    // Fetch All Tag Programs
    const fetchTagPrograms = () => {
        firebase.firestore().collection('programs').orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const programsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;
                        programItem.created = programItem.created_at?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        programItem.updated = programItem.updated_at?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        programsList.push(programItem);
                    });
                    setAllTagPrograms(programsList);
                },
                error => {
                    console.error("Error fetching tag programs:", error);
                }
            );
    };

    // Fetch New Catches with Filters
    const fetchNewCatchesLedger = () => {
        // Unsubscribe from previous listener if exists
        if (subscriptionRef.current) {
            subscriptionRef.current();
            subscriptionRef.current = null;
        }

        const programRef = firebase.firestore().collection('programs').doc(programData.id);
        let newQuery = firebase.firestore().collection('catches')
            .where('tag_program', '==', programRef)
            .where('recatch', '==', false) // Only new catches
            .orderBy('created_at', 'desc');

        // Apply species filter
        if (selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            newQuery = newQuery.where('fish_species', '==', speciesRef);
        }

        // Apply date range filters
        if (startDateFilter && endDateFilter) {
            const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDateFilter));
            const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDateFilter));
            newQuery = newQuery
                .where('catch_date', '>=', startTimestamp)
                .where('catch_date', '<=', endTimestamp);
        }
        else if (startDateFilter) {
            const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDateFilter));
            newQuery = newQuery.where('catch_date', '>=', startTimestamp);
        }
        else if (endDateFilter) {
            const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDateFilter));
            newQuery = newQuery.where('catch_date', '<=', endTimestamp);
        }

        // Set up Firestore listener
        const unsubscribe = newQuery.onSnapshot(
            (querySnapshot) => {
                const catches = [];
                querySnapshot.forEach(doc => {
                    const catchData = doc.data();
                    const catchId = doc.id;
                    catchData.id = catchId;
                    catchData.angler = catchData.user_name || 'Unknown';
                    catchData.user_link = `user/details/${catchData.user?.id || ''}`;
                    catchData.date = catchData.catch_date?.toDate().toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    }) || '';
                    catchData.link = `catch/details/${catchId}`;
                    catchData.tag_link = `tag/details/${catchData.tag?.id || ''}`;
                    catches.push(catchData);
                });
                setNewCatchesLedger(catches);
                setFetchedData(true);
                setCurrentPage(0); // Reset to first page on data fetch
            },
            (error) => {
                console.error("Error fetching new catches:", error);
            }
        );

        subscriptionRef.current = unsubscribe;
    };

    // Get Update Tag Program ID
    const getUpdateTag = async (catchId, catchIndex) => {
        const tagId = newCatchesLedger[catchIndex]?.tag?.id;
        if (!tagId) {
            console.error("Tag ID not found for catch:", catchId);
            return;
        }
        try {
            const tagDoc = await firebase.firestore().collection('tags').doc(tagId).get();
            const tagData = tagDoc.data();
            setUpdateTagProgramId(tagData?.tag_program?.id || '');
        } catch (error) {
            console.error("Error fetching tag data:", error);
        }
    };

    // Handle Form Submission for Updating Catch
    const submitUpdateForm = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        setIsUpdating(true);

        if (updatedImage) {
            await updateImage(event);
        } else {
            await updateCatch(event);
        }
    };

    // Update Image and Then Catch
    const updateImage = async (event) => {
        event.preventDefault();

        const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
        const catchUserId = newCatchesLedger[updateCatchIndex]?.user?.id || 'unknown';
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
            
        const imageFileName = `/catch-images/${catchTagNumber}-${catchUserId}-${catchTimestamp}`;
        const storageRefObj = ref(storage, imageFileName);
    
        const uploadTask = uploadBytesResumable(storageRefObj, updatedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload paused");
                        break;
                    case "running":
                        console.log("Upload running");
                        break;
                    default:
                        break;
                }
            },
            (error) => {
                console.error("Error uploading image:", error);
                setIsUpdating(false);
                alert('Could not update catch image. Please try again.');
            },
            async () => {
                try {
                    const downloadedURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await updateCatch(event, downloadedURL);
                } catch (error) {
                    console.error("Error getting download URL:", error);
                    setIsUpdating(false);
                    alert('Could not retrieve uploaded image URL. Please try again.');
                }
            }
        );
    };

    // Update Catch Details in Firestore
    const updateCatch = async (event, downloadedURL = null) => {
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));
        
        const currentCatch = newCatchesLedger[updateCatchIndex];
        if (!currentCatch) {
            console.error("Current catch data not found.");
            setIsUpdating(false);
            return;
        }

        // Create catchData object with updated fields
        const catchData = {
            updated_at: serverTimestamp(),
        };
                
        if(event.target.catch_name.value !== currentCatch.name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }  
        if(catchTimestampFirebase !== currentCatch.catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }  
        if(event.target.location_string.value !== currentCatch.location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }             
        const newLatitude = parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, ''));
        const newLongitude = parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, ''));
        if(newLatitude !== currentCatch.location.latitude || newLongitude !== currentCatch.location.longitude) {
            catchData['location'] = new firebase.firestore.GeoPoint(newLatitude, newLongitude);
        }
        if(event.target.location_area.value !== currentCatch.location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if(event.target.location_city.value !== currentCatch.location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        } 
        if(event.target.state_string.value !== currentCatch.location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if(event.target.state_code.value !== currentCatch.location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if(event.target.catch_country.value !== currentCatch.location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }
                
        if(event.target.tag_number.value !== currentCatch.tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(speciesId !== currentCatch.fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(speciesId);
            catchData['species_name'] = allSpecies[speciesIndex]?.name || '';
        }

        if(currentCatch.length !== event.target.fish_length.value) {
            catchData['length'] = event.target.fish_length.value.replace(/[^0-9.,]/g, '');
        }
        if(currentCatch.weight !== event.target.fish_weight.value) {
            catchData['weight'] = event.target.fish_weight.value.replace(/[^0-9.,]/g, '');
        }
        if(currentCatch.info !== event.target.catch_info.value) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }

        if(downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }
                
        // Update catch in Firestore
        const catchRef = firebase.firestore().collection('catches').doc(currentCatch.id);
        try {
            await catchRef.update(catchData);
            alert('The catch has been updated successfully!');
            fetchNewCatchesLedger(); 
            setIsUpdating(false);
            setUpdatedImage(null);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating catch:", error);
            setIsUpdating(false);
            alert('Could not update catch entry. Please try again.');
        }
    };

    // Handle CSV Download
    const handleDownloadCSV = async () => {
        try {
            const programRef = firebase.firestore().collection('programs').doc(programData.id);
            let csvQuery = firebase.firestore().collection('catches')
                .where('tag_program', '==', programRef)
                .where('recatch', '==', false) // New catches only
                .orderBy('created_at', 'desc');

            // Apply species filter
            if (selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species', '==', speciesRef);
            }

            // Apply date range filters
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            
            // CSV Header
            csvRows.push([
                'Catch ID',
                'Catch Name',
                'Angler Name',
                'Location String',
                'location_area',
                'location_city',
                'location_state',
                'location_state_code',
                'location_country',
                'Tag Number',
                'Catch Date',
                'Catch Time',
                'Recatch?',
                'info',
                'image_url',
                'length',
                'weight',
                'metric_system',
                'species_name',
                'location_lat',
                'location_lng'
            ].join(','));

            snapshot.forEach((doc) => {
                const data = doc.data();
                const lat = data.location ? data.location.latitude : '';
                const lng = data.location ? data.location.longitude : '';
                const row = [
                    doc.id,
                    (data.name || '').replace(/,/g, ''),
                    (data.user_name || '').replace(/,/g, ''),
                    (data.location_string || '').replace(/,/g, ''),
                    (data.location_area || '').replace(/,/g, ''),
                    (data.location_city || '').replace(/,/g, ''),
                    (data.location_state || '').replace(/,/g, ''),
                    (data.location_state_code || '').replace(/,/g, ''),
                    (data.location_country || '').replace(/,/g, ''),
                    (data.tag_number || '').replace(/,/g, ''),
                    data.catch_date
                        ? data.catch_date.toDate().toLocaleString()
                        : '',
                    data.recatch ? 'Yes' : 'No',
                    (data.info || '').replace(/,/g, ''),
                    (data.image_url || '').replace(/,/g, ''),
                    (data.length || '').toString().replace(/,/g, ''),
                    (data.weight || '').toString().replace(/,/g, ''),
                    typeof data.metric_system !== 'undefined' ? data.metric_system : '',
                    (data.species_name || '').replace(/,/g, ''),
                    lat,
                    lng
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filtered_new_catches.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating CSV:', error);
            alert('Could not download CSV. Please try again.');
        }
    };

    // Handle Applying Filters
    const handleApplyFilters = () => {
        fetchNewCatchesLedger();
    };

    // Delete Catch Functionality
    const deleteCatch = async () => {
        setIsDeleting(true);
        
        const catchItem = newCatchesLedger[deleteCatchIndex];
        if (!catchItem) {
            console.error("Catch item not found for deletion.");
            setIsDeleting(false);
            alert('Invalid catch data.');
            return;
        }

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        try {
            // Get all catches using the same tag
            const tagCatchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();
            const batch = firebase.firestore().batch();

            tagCatchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const userRef = usersRef.doc(catchData.user.id);
                const userUpdate = {
                    updated_at: serverTimestamp(),
                };
                if(catchData.recatch) {                        
                    userUpdate['recatch_count'] = catchData.recatch_count > 1 
                        ? firebase.firestore.FieldValue.increment(-1) 
                        : 0; 
                }  
                else {
                    userUpdate['catch_count'] = catchData.catch_count > 1 
                        ? firebase.firestore.FieldValue.increment(-1) 
                        : 0;
                }
                batch.update(userRef, userUpdate);
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            // Commit the batch
            await batch.commit();

            console.log('Deleted catch and related data successfully!');
            fetchNewCatchesLedger();
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } catch (error) {
            console.error("Error deleting catch:", error);
            alert('Could not delete catch entry. Please try again.');
        } finally {
            setIsDeleting(false);
        }
    };

    // Restore Catch Functionality
    const restoreCatch = async () => {
        setIsRestoringCatch(true);
        
        const catchItem = newCatchesLedger[restoreCatchIndex];
        if (!catchItem) {
            console.error("Catch item not found for restoration.");
            setIsRestoringCatch(false);
            alert('Invalid catch data.');
            return;
        }

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        try {
            // Get all catches using the same tag
            const tagCatchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();
            const batch = firebase.firestore().batch();

            tagCatchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const userRef = usersRef.doc(catchData.user.id);
                const userUpdate = {
                    updated_at: serverTimestamp(),
                };
                if(catchData.recatch) {                        
                    userUpdate['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                }  
                else {
                    userUpdate['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                batch.update(userRef, userUpdate);
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            // Commit the batch
            await batch.commit();

            console.log('Restored catch and related data successfully!');
            fetchNewCatchesLedger();
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } catch (error) {
            console.error("Error restoring catch:", error);
            alert('Could not restore catch entry. Please try again.');
        } finally {
            setIsRestoringCatch(false);
        }
    };

    // Fetch Data on Initial Load
    useEffect(() => {
        if(initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchTagPrograms();
            fetchNewCatchesLedger();
        }
    }, [initialLoad]);

    // Cleanup on Unmount
    useEffect(() => {
        return () => {
            if(subscriptionRef.current) {
                subscriptionRef.current();
            }
        };
    }, []);

    // Derived Data: Filtered and Searched Catches
    const filteredCatches = newCatchesLedger.filter(catchItem => {
        const matchesSearch = 
            catchItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            catchItem.angler.toLowerCase().includes(searchQuery.toLowerCase()) ||
            catchItem.location_string.toLowerCase().includes(searchQuery.toLowerCase()) ||
            catchItem.tag_number.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesSearch;
    });

    // Pagination Logic
    const pageCount = Math.ceil(filteredCatches.length / catchesPerPage);
    const offset = currentPage * catchesPerPage;
    const currentCatches = filteredCatches.slice(offset, offset + catchesPerPage);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Catch Ledger</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">New Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="new-catch-list">
                                        
                                        {/* Filter and Action Row */}
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button 
                                                        color="success" 
                                                        disabled 
                                                        className="add-btn" 
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>Add Catch
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-start">
                                                    <div className="search-box ms-2">
                                                        <input 
                                                            type="search" 
                                                            className="form-control" 
                                                            placeholder="Search Catches..." 
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        
                                            {/* Species Filter */}
                                            <Col sm="2" md="2">
                                                <select 
                                                    className="form-control" 
                                                    value={selectedSpeciesFilter}
                                                    onChange={(e) => setSelectedSpeciesFilter(e.target.value)}
                                                >
                                                    <option value="">All Species</option>
                                                    {allSpecies.map(spec => (
                                                        <option key={spec.id} value={spec.id}>
                                                            {spec.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>
                                        
                                            {/* Start Date Filter */}
                                            <Col sm="2" md="2">
                                                <Flatpickr
                                                    className="form-control"
                                                    value={startDateFilter}
                                                    onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                                    options={{ 
                                                        dateFormat: "Y-m-d",
                                                        maxDate: endDateFilter ? endDateFilter : undefined
                                                    }}
                                                    placeholder="Start Date"
                                                />
                                            </Col>
                                        
                                            {/* End Date Filter */}
                                            <Col sm="2" md="2">
                                                <Flatpickr
                                                    className="form-control"
                                                    value={endDateFilter}
                                                    onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                                    options={{ 
                                                        dateFormat: "Y-m-d",
                                                        minDate: startDateFilter ? startDateFilter : undefined
                                                    }}
                                                    placeholder="End Date"
                                                />
                                            </Col>

                                            {/* Apply Filter Button */}
                                            <Col sm="1" md="1" className="d-flex align-items-end">
                                                <Button 
                                                    color="secondary" 
                                                    onClick={handleApplyFilters}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-filter" ></i>
                                                </Button>
                                            </Col>

                                            {/* Download CSV Button */}
                                            <Col sm="1" md="1" className="d-flex align-items-end">
                                                <Button 
                                                    color="primary" 
                                                    onClick={handleDownloadCSV}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-download" ></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/* End Filter and Action Row */}

                                        {/* CSV Upload Row (Optional) */}
                                        {/* <Row className="g-4 mb-3">
                                            <Col sm="6" md="4" className="d-flex align-items-end">
                                                <label htmlFor="csvFile" className="form-label">Upload CSV</label>
                                                <input
                                                    type="file"
                                                    accept=".csv"
                                                    style={{ marginLeft: '10px' }}
                                                    onChange={handleCsvFileChange}
                                                />
                                            </Col>
                                            <Col sm="6" md="3" className="d-flex align-items-end">
                                                <Button
                                                    color="info"
                                                    onClick={handleUploadCSV}
                                                    style={{ width: '100%' }}
                                                    disabled={isCsvUploading}
                                                >
                                                    {isCsvUploading ? 'Uploading...' : 'Upload CSV'}
                                                </Button>
                                            </Col>
                                        </Row> */}
                                        {/* End CSV Upload Row */}

                                        <div className="table-responsive table-card mt-3 mb-1">
                                        {!isUpdating && !isDeleting &&
                                            <table className="table align-middle table-nowrap" id="new-catch-table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th className="sort" data-sort="image_url">Image</th>
                                                        <th className="sort" data-sort="name">Catch Name</th>
                                                        <th className="sort" data-sort="angler">Angler Name</th>
                                                        <th className="sort" data-sort="location_string">Catch Location</th>
                                                        <th className="sort" data-sort="tag_number">Tag Number</th>
                                                        <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                                        <th className="sort" data-sort="type">Type</th>
                                                        <th className="sort" data-sort="action">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                
                                                    {currentCatches.length > 0 ? (
                                                        currentCatches.map((catchItem) => ( 
                                                            <tr key={catchItem.id}>
                                                                <th scope="row">
                                                                    <a 
                                                                        className="btn btn-sm btn-success edit-item-btn" 
                                                                        href={catchItem.link}
                                                                    >
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </a>
                                                                </th>
                                                                <td className="image_url">
                                                                    {catchItem.image_url && (
                                                                        <img 
                                                                            className="avatar-sm rounded" 
                                                                            alt={catchItem.name} 
                                                                            src={catchItem.image_url} 
                                                                        />
                                                                    )}
                                                                </td>
                                                                <td className="name">{catchItem.name}</td>
                                                                <td>
                                                                    <a 
                                                                        href={catchItem.user_link} 
                                                                        className="angler fw-medium link-primary"
                                                                    >
                                                                        {catchItem.angler}
                                                                    </a>
                                                                </td>
                                                                <td className="location_string">{catchItem.location_string}</td>
                                                                <td>
                                                                    <a 
                                                                        href={catchItem.tag_link} 
                                                                        className="tag_number fw-medium link-primary"
                                                                    >
                                                                        #{catchItem.tag_number}
                                                                    </a>
                                                                </td>
                                                                <td className="date">{catchItem.date}</td>
                                                                <td className="type">
                                                                    {catchItem.recatch ? (
                                                                        <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                                    ) : (
                                                                        <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        <div className="edit">
                                                                            <button 
                                                                                className="btn btn-sm btn-warning edit-item-btn"
                                                                                onClick={() => toggleEditModal(catchItem.id, newCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                            >
                                                                                <i className="mdi mdi-square-edit-outline"></i>
                                                                            </button>
                                                                        </div>
                                                                        {!catchItem.deleted && (
                                                                            <div className="remove">
                                                                                <button 
                                                                                    className="btn btn-sm btn-danger remove-item-btn" 
                                                                                    onClick={() => toggleDeleteModal(catchItem.id, newCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                >
                                                                                    <i className="mdi mdi-delete-outline"></i>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                        {catchItem.deleted && (
                                                                            <div className="remove">
                                                                                <button 
                                                                                    className="btn btn-sm btn-success remove-item-btn" 
                                                                                    onClick={() => toggleRestoreCatchModal(catchItem.id, newCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                >
                                                                                    <i className="mdi mdi-history"></i>
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Catches Found!</td>
                                                        </tr>
                                                    )}
                                                    
                                                    
                                                </tbody>
                                            </table>
                                        }
                                        
                                        {newCatchesLedger.length === 0 && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Catches Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any catches.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {/* Pagination Controls */}
                                    {filteredCatches.length > catchesPerPage && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-center"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </div>
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
        
        {/* Edit Modal */}
        <Modal isOpen={editModal} toggle={toggleEditModal} centered>
            <ModalHeader className="bg-light p-3" toggle={toggleEditModal}>
                Edit Catch
            </ModalHeader>
            
            {!isUpdating && newCatchesLedger[updateCatchIndex] && (
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalBody>            
                        <div className="mb-3">
                            <label htmlFor="name-field" className="form-label">Catch Name*</label>
                            <input 
                                type="text" 
                                name="catch_name" 
                                id="name-field" 
                                className="form-control" 
                                placeholder="Enter Name" 
                                required 
                                defaultValue={newCatchesLedger[updateCatchIndex].name}
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Catch Date*</label>
                            <Flatpickr
                                className="form-control"
                                name="catch_datetime"
                                options={{
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    defaultDate: newCatchesLedger[updateCatchIndex].catch_date.toDate()
                                }}
                                placeholder="Select Date & Time"
                            />
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="image-field" className="form-label">Image File*</label>
                            <div className="d-flex align-items-center">
                                <img 
                                    className="avatar-sm rounded me-3" 
                                    alt={newCatchesLedger[updateCatchIndex].name} 
                                    src={newCatchesLedger[updateCatchIndex].image_url || '/default-image.png'} 
                                />
                                <input 
                                    type="file" 
                                    name="image_link" 
                                    id="image-field" 
                                    className="form-control" 
                                    onChange={(e) => { setUpdatedImage(e.target.files[0]) }} 
                                />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="locationstring-field" className="form-label">Full Catch Location*</label>
                            <input 
                                type="text" 
                                id="locationstring-field" 
                                className="form-control" 
                                name="location_string" 
                                placeholder="Toms River" 
                                required 
                                defaultValue={newCatchesLedger[updateCatchIndex].location_string}
                            />
                        </div>

                        <div className="mb-3 row">
                            <div className="col">
                                <label htmlFor="latitude-field" className="form-label">Latitude*</label>
                                <input 
                                    type="number" 
                                    id="latitude-field" 
                                    className="form-control" 
                                    placeholder="Enter Latitude*" 
                                    name="catch_latitude" 
                                    step="any" 
                                    required 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location.latitude} 
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="longitude-field" className="form-label">Longitude*</label>
                                <input 
                                    type="number" 
                                    id="longitude-field" 
                                    className="form-control" 
                                    placeholder="Enter Longitude*" 
                                    name="catch_longitude" 
                                    step="any" 
                                    required 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location.longitude} 
                                />
                            </div>
                        </div>
                        
                        <div className="mb-3 row">
                            <div className="col">
                                <label htmlFor="locationarea-field" className="form-label">Catch Location Area*</label>
                                <input 
                                    type="text" 
                                    id="locationarea-field" 
                                    className="form-control" 
                                    name="location_area" 
                                    placeholder="Toms River" 
                                    required 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_area}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="locationcity-field" className="form-label">Catch Location City*</label>
                                <input 
                                    type="text" 
                                    id="locationcity-field" 
                                    className="form-control" 
                                    name="location_city" 
                                    placeholder="Atlantic City" 
                                    required 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_city}
                                />
                            </div>
                        </div>
                        
                        <div className="mb-3 row">
                            <div className="col">
                                <label htmlFor="state-field" className="form-label">Catch State*</label>
                                <input 
                                    type="text" 
                                    id="state-field" 
                                    className="form-control" 
                                    name="state_string" 
                                    placeholder="New York" 
                                    required 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_state}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="statecode-field" className="form-label">State Code*</label>
                                <input 
                                    type="text" 
                                    id="statecode-field" 
                                    className="form-control" 
                                    name="state_code" 
                                    placeholder="NY" 
                                    required 
                                    maxLength={2} 
                                    defaultValue={newCatchesLedger[updateCatchIndex].location_state_code}
                                />
                            </div>
                        </div>
                    
                        <div className="mb-3">
                            <label htmlFor="country-field" className="form-label">Catch Country*</label>
                            <select 
                                className="form-control" 
                                name="catch_country" 
                                id="country-field" 
                                required 
                                defaultValue={newCatchesLedger[updateCatchIndex].location_country}
                            >
                                <option value="">Select Catch Country</option>
                                <option value="USA">United States</option>
                                <option value="CAN">Canada</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="tag-field" className="form-label">Tag Number*</label>
                            <input 
                                type="text" 
                                id="tag-field" 
                                name="tag_number" 
                                className="form-control" 
                                placeholder="Enter Tag Number*" 
                                maxLength={15} 
                                required 
                                defaultValue={newCatchesLedger[updateCatchIndex].tag_number} 
                            />
                        </div>
                
                        <div className="mb-3">
                            <label htmlFor="species-field" className="form-label">Fish Species*</label>
                            <select 
                                className="form-control" 
                                name="fish_species" 
                                id="species-field" 
                                required 
                                defaultValue={newCatchesLedger[updateCatchIndex].fish_species.id}
                            >
                                <option value="">Select Fish Species*</option>
                                {allSpecies.map(spec => (
                                    <option key={spec.id} value={spec.id}>{spec.name}</option>
                                ))}
                            </select>
                        </div>   
                        
                        <div className="mb-3 row">
                            <div className="col">
                                {newCatchesLedger[updateCatchIndex].metric_system ? (
                                    <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>
                                ) : (
                                    <label htmlFor="length-field" className="form-label">Fish Length (IN)</label>
                                )}
                                <input 
                                    type="number" 
                                    id="length-field" 
                                    className="form-control" 
                                    placeholder="Enter Fish Length" 
                                    defaultValue={newCatchesLedger[updateCatchIndex].length} 
                                    name="fish_length"
                                />
                            </div>
                            <div className="col">
                                {newCatchesLedger[updateCatchIndex].metric_system ? (
                                    <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>
                                ) : (
                                    <label htmlFor="weight-field" className="form-label">Fish Weight (LB)</label>
                                )}
                                <input 
                                    type="number" 
                                    id="weight-field" 
                                    className="form-control" 
                                    placeholder="Enter Fish Weight" 
                                    defaultValue={newCatchesLedger[updateCatchIndex].weight} 
                                    name="fish_weight"
                                />
                            </div>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="catchinfo-field" className="form-label">Catch Information</label>
                            <input 
                                type="text" 
                                id="catchinfo-field" 
                                className="form-control" 
                                placeholder="Catch Information" 
                                defaultValue={newCatchesLedger[updateCatchIndex].info} 
                                name="catch_info"
                            />
                        </div>
            
                        {/* 
                          <div className="mb-3 row">
                              <div className="col">
                                  <label htmlFor="status-field" className="form-label">Catch Type*</label>
                                  <select className="form-control" name="catch_type" id="status-field" required defaultValue={newCatchesLedger[updateCatchIndex].recatch}>
                                      <option value="">Select Catch Type*</option>
                                      <option value="false">New Catch</option>
                                      <option value="true">Re-Catch</option>
                                  </select>
                              </div>
                              <div className="col">
                                  <label htmlFor="units-field" className="form-label">Unit System*</label>
                                  <select className="form-control" name="catch_units" id="units-field" required defaultValue={newCatchesLedger[updateCatchIndex].metric_system}>
                                      <option value="">Select Units System*</option>
                                      <option value="true">Metric System</option>
                                      <option value="false">Imperial System</option>
                                  </select>
                              </div>
                          </div> 
                        */}
                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button 
                                type="button" 
                                className="btn btn-light" 
                                onClick={toggleEditModal}
                            >
                                Close
                            </button>
                            <button 
                                type="submit" 
                                className="btn btn-success"
                            >
                                Update
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            )}
            {isUpdating && (
                <ModalBody>
                    <div className="d-flex justify-content-center my-5">
                        <ThreeCircles
                            visible={isUpdating}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{ margin: "0 auto" }}
                            wrapperClass="justify-content-center"
                        />
                    </div>
                </ModalBody>
            )}
        </Modal>

        {/* Add Modal */}
        <Modal isOpen={modalList} toggle={toggleModalList} centered>
            <ModalHeader className="bg-light p-3" toggle={toggleModalList}>
                Add Catch
            </ModalHeader>
            <form className="tablelist-form">
                <ModalBody>
                    <div className="mb-3" id="modal-id" style={{ display: "none" }}>
                        <label htmlFor="id-field" className="form-label">ID</label>
                        <input type="text" id="id-field" className="form-control" placeholder="ID" readOnly />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="name-field" className="form-label">Catch Name*</label>
                        <input 
                            type="text" 
                            id="name-field" 
                            className="form-control" 
                            placeholder="Enter Name" 
                            required 
                        />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Catch Date</label>
                        <Flatpickr
                            className="form-control"
                            options={{
                                enableTime: true,
                                dateFormat: "d M, Y H:i"
                            }}
                            placeholder="Select Date & Time"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="latitude-field" className="form-label">Latitude*</label>
                        <input 
                            type="number" 
                            id="latitude-field" 
                            className="form-control" 
                            placeholder="Enter Latitude*" 
                            required 
                        />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="longitude-field" className="form-label">Longitude*</label>
                        <input 
                            type="number" 
                            id="longitude-field" 
                            className="form-control" 
                            placeholder="Enter Longitude*" 
                            required 
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="tag-field" className="form-label">Tag Number*</label>
                        <input 
                            type="text" 
                            id="tag-field" 
                            className="form-control" 
                            placeholder="Enter Tag Number*" 
                            required 
                            maxLength={15} 
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="species-field" className="form-label">Fish Species*</label>
                        <select 
                            className="form-control" 
                            name="species-field" 
                            id="species-field" 
                            required 
                        >
                            <option value="">Select Fish Species*</option>
                            {allSpecies.map(spec => (
                                <option key={spec.id} value={spec.id}>{spec.name}</option>
                            ))}
                        </select>
                    </div>   
                    
                    <div className="mb-3">
                        <label htmlFor="length-field" className="form-label">Fish Length (CM)</label>
                        <input 
                            type="number" 
                            id="length-field" 
                            className="form-control" 
                            placeholder="Enter Fish Length" 
                            required 
                        />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="weight-field" className="form-label">Fish Weight (KG)</label>
                        <input 
                            type="number" 
                            id="weight-field" 
                            className="form-control" 
                            placeholder="Enter Fish Weight*" 
                            required 
                        />
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="program-field" className="form-label">Tagging Program*</label>
                        <select 
                            className="form-control" 
                            name="program-field" 
                            id="program-field" 
                            required 
                        >
                            <option value="">Select Tag Program*</option>
                            {allTagPrograms.map(program => (
                                <option key={program.id} value={program.id}>{program.name}</option>
                            ))}
                        </select>
                    </div>   

                    <div className="mb-3">
                        <label htmlFor="status-field" className="form-label">Type</label>
                        <select 
                            className="form-control" 
                            name="status-field" 
                            id="status-field" 
                            required 
                        >
                            <option value="">Select Type*</option>
                            <option value="false">New Catch</option>
                            <option value="true">Re-Catch</option>
                        </select>
                    </div>
                    
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <button 
                            type="button" 
                            className="btn btn-light" 
                            onClick={toggleModalList}
                        >
                            Close
                        </button>
                        <button 
                            type="submit" 
                            className="btn btn-success disabled" 
                            id="add-btn"
                        >
                            Add Catch
                        </button>
                    </div>
                </ModalFooter>
            </form>
        </Modal>

        {/* Delete Modal */}
        <Modal 
            isOpen={deleteModal} 
            toggle={toggleDeleteModal} 
            className="modal zoomIn" 
            id="deleteRecordModal" 
            centered 
        >
            {!isDeleting && (
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Delete Catch Data</h4><br/>
                            <h5 className="text-muted mx-4 mb-0">
                                This will delete the catch, connected re-catches and the fish-tag it used!!!
                            </h5>
                            <br/>
                            <p className="text-muted mx-4 mb-0">
                                Are you sure you want to delete this catch?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button 
                            type="button" 
                            className="btn w-sm btn-light" 
                            onClick={() => toggleDeleteModal()}
                        >
                            Cancel
                        </button>
                        <button 
                            type="button" 
                            className="btn w-sm btn-danger" 
                            id="delete-record" 
                            onClick={deleteCatch}
                        >
                            Delete
                        </button>
                    </div>
                </ModalBody>
            )}
            {isDeleting && (
                <ModalBody>
                    <div className="d-flex justify-content-center my-5">
                        <ThreeCircles
                            visible={isDeleting}
                            height="100"
                            width="100"
                            color="#ff3d60"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{ margin: "0 auto" }}
                            wrapperClass="justify-content-center"
                        />
                    </div>
                </ModalBody>
            )}
        </Modal>
        
        {/* Restore Modal */}
        <Modal 
            isOpen={restoreCatchModalState} 
            toggle={toggleRestoreCatchModal} 
            className="modal zoomIn" 
            id="restoreCatchModal" 
            centered
        >
            {!isRestoringCatch && (
                <ModalBody>
                    <div className="mt-2 text-center">
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Restore Catch Data</h4><br/>
                            <h5 className="text-muted mx-4 mb-0">
                                This will restore the catch, connected re-catches and the fish-tag it used!!!
                            </h5>
                            <br/>
                            <p className="text-muted mx-4 mb-0">
                                Are you sure you want to restore this catch?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button 
                            type="button" 
                            className="btn w-sm btn-light" 
                            onClick={() => toggleRestoreCatchModal()}
                        >
                            Cancel
                        </button>
                        <button 
                            type="button" 
                            className="btn w-sm btn-success" 
                            id="delete-record" 
                            onClick={restoreCatch}
                        >
                            Restore
                        </button>
                    </div>
                </ModalBody>
            )}
            {isRestoringCatch && (
                <ModalBody>
                    <div className="d-flex justify-content-center my-5">
                        <ThreeCircles
                            visible={isRestoringCatch}
                            height="100"
                            width="100"
                            color="#4192C3"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{ margin: "0 auto" }}
                            wrapperClass="justify-content-center"
                        />
                    </div>
                </ModalBody>
            )}
        </Modal>
    </React.Fragment>
);

};

export default NewCatches;