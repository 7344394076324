// AllCatches.jsx

import React, { useRef, useState, useEffect, useMemo } from 'react';
import { 
    Button, 
    Card, 
    CardBody, 
    CardTitle, 
    CardHeader, 
    Col, 
    Container, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    Row, 
    ModalHeader, 
    Input, 
    FormGroup, 
    Label 
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import { ThreeCircles } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import Papa from 'papaparse'; // Import PapaParse for CSV parsing
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const AllCatches = () => {
    
    const storage = getStorage();
    
    const [initialLoad, setInitialLoad] = useState(true);
    const [allCatchesLedger, setAllCatchesLedger] = useState([]);
    const [fetchedData, setFetchedData] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [allSpecies, setAllSpecies] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    
    // NEW FILTER STATES (Species, Date Range)
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    // Search and Pagination States
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const catchesPerPage = 10;

    // Reference to store Firestore listener for catches
    const subscriptionRef = useRef(null);

    // Action buttons states
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateCatchId, setUpdateCatchId] = useState('');
    const [updateCatchIndex, setUpdateCatchIndex] = useState(null);
    const [updateTagProgramId, setUpdateTagProgramId] = useState('');
    const [deleteCatchId, setDeleteCatchId] = useState('');
    const [deleteCatchIndex, setDeleteCatchIndex] = useState(null);
    const [isDeletingRecatch, setIsDeletingRecatch] = useState(false);
    const [isDeletingCatch, setIsDeletingCatch] = useState(false);
    const [isRestoringRecatch, setIsRestoringRecatch] = useState(false);
    const [isRestoringCatch, setIsRestoringCatch] = useState(false);
    const [restoreCatchId, setRestoreCatchId] = useState('');
    const [restoreCatchIndex, setRestoreCatchIndex] = useState(null);
    const [updatedImage, setUpdatedImage] = useState(null);
    
    // Session data references
    const userData = JSON.parse(localStorage.getItem("userData")) || {};
    const programData = JSON.parse(localStorage.getItem("programData")) || {};
    
    // Set document title
    useEffect(() => {
        document.title = "Catch Ledger | Fish-Trak - Partner's Dashboard";
    }, []);

    // Modal state handlers
    const [modalList, setModalList] = useState(false);
    const toggleModalList = () => setModalList(!modalList);
    
    const [deleteRecatchModal, setDeleteRecatchModal] = useState(false);
    const toggleDeleteRecatchModal = (catchId = '', catchIndex = null) => {
        setDeleteCatchId(catchId);
        setDeleteCatchIndex(catchIndex);
        setDeleteRecatchModal(!deleteRecatchModal);
    };
    
    const [deleteCatchModal, setDeleteCatchModal] = useState(false);
    const toggleDeleteCatchModal = (catchId = '', catchIndex = null) => {
        setDeleteCatchId(catchId);
        setDeleteCatchIndex(catchIndex);
        setDeleteCatchModal(!deleteCatchModal);
    };
    
    const [restoreRecatchModal, setRestoreRecatchModal] = useState(false);
    const toggleRestoreRecatchModal = (catchId = '', catchIndex = null) => {
        setRestoreCatchId(catchId);
        setRestoreCatchIndex(catchIndex);
        setRestoreRecatchModal(!restoreRecatchModal);
    };
    
    const [restoreCatchModal, setRestoreCatchModal] = useState(false);
    const toggleRestoreCatchModal = (catchId = '', catchIndex = null) => {
        setRestoreCatchId(catchId);
        setRestoreCatchIndex(catchIndex);
        setRestoreCatchModal(!restoreCatchModal);
    };
    
    const [editModal, setEditModal] = useState(false);
    const toggleEditModal = (catchId = '', catchIndex = null) => {
        if(editModal) {
            setUpdateCatchId('');
            setUpdateCatchIndex(null);
            setUpdateTagProgramId('');
        }
        else {
            if (catchId && catchIndex !== null) {
                getUpdateTag(catchId, catchIndex);
                setUpdateCatchId(catchId);
                setUpdateCatchIndex(catchIndex);
            }
        }
        setEditModal(!editModal);
    };

    // CSV Upload States
    const [csvFile, setCsvFile] = useState(null);
    const [isCsvUploading, setIsCsvUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadCSVModal, setUploadCSVModal] = useState(false); // Modal state for CSV Upload

    // Fetch species data
    const fetchAllSpecies = () => {
        firebase.firestore().collection('species').orderBy('name', 'asc')
            .onSnapshot(
                querySnapshot => {
                    const newSpeciesList = [];
                    querySnapshot.forEach(doc => {
                        const speciesItem = doc.data();
                        speciesItem.id = doc.id;
                        newSpeciesList.push(speciesItem);
                    });
                    setAllSpecies(newSpeciesList);
                },
                error => {
                    console.error("Error fetching species:", error);
                    toast.error("Error fetching species.");
                }
            );
    };

    // Fetch tag programs data
    const fetchTagPrograms = () => {
        firebase.firestore().collection('programs').orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;
                        programItem.created = programItem.created_at?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        programItem.updated = programItem.updated_at?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.error("Error fetching tag programs:", error);
                    toast.error("Error fetching tag programs.");
                }
            );
    };

    // Fetch all catches with filters
    const fetchAllCatchesLedger = () => {
        // Unsubscribe from previous listener if exists
        if (subscriptionRef.current) {
            subscriptionRef.current();
            subscriptionRef.current = null;
        }

        const programRef = firebase.firestore().collection('programs').doc(programData.id);
        let newQuery = firebase.firestore().collection('catches')
            .where('tag_program', '==', programRef)
            .orderBy('created_at', 'desc');

        // Apply species filter
        if (selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            newQuery = newQuery.where('fish_species', '==', speciesRef);
        }

        // Apply date range filters
        if (startDateFilter && endDateFilter) {
            const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDateFilter));
            const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDateFilter));
            newQuery = newQuery
                .where('catch_date', '>=', startTimestamp)
                .where('catch_date', '<=', endTimestamp);
        }
        else if (startDateFilter) {
            const startTimestamp = firebase.firestore.Timestamp.fromDate(new Date(startDateFilter));
            newQuery = newQuery.where('catch_date', '>=', startTimestamp);
        }
        else if (endDateFilter) {
            const endTimestamp = firebase.firestore.Timestamp.fromDate(new Date(endDateFilter));
            newQuery = newQuery.where('catch_date', '<=', endTimestamp);
        }

        // Set up Firestore listener
        const unsubscribe = newQuery.onSnapshot(
            (querySnapshot) => {
                const newCatchLedger = [];
                querySnapshot.forEach((doc) => {
                    const catchItem = doc.data();
                    const catchId = doc.id;
                    catchItem.id = catchId;
                    catchItem.angler = catchItem.user_name || 'Unknown';
                    catchItem.user_link = `../user/details/${catchItem.user?.id || ''}`;

                    const catchDate = catchItem.catch_date?.toDate().toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    }) || '';
                    catchItem.date = catchDate;
                    catchItem.link = `../catch/details/${catchId}`;
                    catchItem.tag_link = `../tag/details/${catchItem.tag?.id || ''}`;
                    newCatchLedger.push(catchItem);
                });
                setAllCatchesLedger(newCatchLedger);
                setFetchedData(true);
                setCurrentPage(0); // Reset to first page on data fetch
            },
            (error) => {
                console.error("Error fetching catches:", error);
                toast.error("Error fetching catches.");
            }
        );

        subscriptionRef.current = unsubscribe;
    };

    // Fetch update tag program ID
    const getUpdateTag = async (catchId, catchIndex) => {
        const tagId = allCatchesLedger[catchIndex]?.tag?.id;
        if (!tagId) {
            console.error("Tag ID not found for catch:", catchId);
            toast.error("Tag ID not found for catch.");
            return;
        }
        try {
            const tagDoc = await firebase.firestore().collection('tags').doc(tagId).get();
            const tagData = tagDoc.data();
            setUpdateTagProgramId(tagData?.tag_program?.id || '');
        } catch (error) {
            console.error("Error fetching tag data:", error);
            toast.error("Error fetching tag data.");
        }
    };

    // Handle form submission for updating catch
    const submitUpdateForm = async (event) => {
        setIsUploading(true);
        event.preventDefault();
        setIsUpdating(true);

        if (updatedImage) {
            await updateImage(event);
        } else {
            await updateCatch(event);
        }
    };

    // Update image and then catch
    const updateImage = async (event) => {
        event.preventDefault();

        const catchTimestamp = event.target.catch_datetime.value.replace(/[^0-9-]/g, '-');
        const catchUserId = allCatchesLedger[updateCatchIndex]?.user?.id || 'unknown';
        const catchTagNumber = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
            
        const imageFileName = `/catch-images/${catchTagNumber}-${catchUserId}-${catchTimestamp}`;
        const storageRefObj = ref(storage, imageFileName);
    
        const uploadTask = uploadBytesResumable(storageRefObj, updatedImage);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
            },
            (error) => {
                console.error("Error uploading image:", error);
                setIsUpdating(false);
                toast.error('Could not update catch image. Please try again.');
            },
            async () => {
                try {
                    const downloadedURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await updateCatch(event, downloadedURL);
                } catch (error) {
                    console.error("Error getting download URL:", error);
                    setIsUpdating(false);
                    toast.error('Could not retrieve uploaded image URL. Please try again.');
                }
            }
        );
    };

    // Update catch details in Firestore
    const updateCatch = async (event, downloadedURL = null) => {
        const speciesId = event.target.fish_species.value;
        const speciesIndex = event.target.fish_species.selectedIndex - 1;
        const catchTimestampFirebase = firebase.firestore.Timestamp.fromDate(new Date(event.target.catch_datetime.value));
        
        const catchData = {
            updated_at: serverTimestamp(),
        };
                
        // Compare and update fields
        const currentCatch = allCatchesLedger[updateCatchIndex];
        if(event.target.catch_name.value !== currentCatch.name) {
            catchData['name'] = event.target.catch_name.value.replace(/[^0-9a-zA-Z'-,.?& ]/g, '');
        }  
        if(catchTimestampFirebase !== currentCatch.catch_date) {
            catchData['catch_date'] = catchTimestampFirebase;
        }  
        if(event.target.location_string.value !== currentCatch.location_string) {
            catchData['location_string'] = event.target.location_string.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }             
        const newLatitude = parseFloat(event.target.catch_latitude.value.replace(/[^0-9.-]/g, ''));
        const newLongitude = parseFloat(event.target.catch_longitude.value.replace(/[^0-9.-]/g, ''));
        if(newLatitude !== currentCatch.location.latitude || newLongitude !== currentCatch.location.longitude) {
            catchData['location'] = new firebase.firestore.GeoPoint(newLatitude, newLongitude);
        }
        if(event.target.location_area.value !== currentCatch.location_area) {
            catchData['location_area'] = event.target.location_area.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        }
        if(event.target.location_city.value !== currentCatch.location_city) {
            catchData['location_city'] = event.target.location_city.value.replace(/[^0-9a-zA-Z'-,.()/;:"&+_ ]/g, '');
        } 
        if(event.target.state_string.value !== currentCatch.location_state) {
            catchData['location_state'] = event.target.state_string.value.replace(/[^a-zA-Z ]/g, '');
        }
        if(event.target.state_code.value !== currentCatch.location_state_code) {
            catchData['location_state_code'] = event.target.state_code.value.replace(/[^a-zA-Z]/g, '');
        }
        if(event.target.catch_country.value !== currentCatch.location_country) {
            catchData['location_country'] = event.target.catch_country.value.replace(/[^a-zA-Z-,&+.' ]/g, '');
        }
                
        if(event.target.tag_number.value !== currentCatch.tag_number) {
            catchData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
        }
        if(speciesId !== currentCatch.fish_species.id) {
            catchData['fish_species'] = firebase.firestore().collection('species').doc(speciesId);
            catchData['species_name'] = allSpecies[speciesIndex]?.name || '';
        }

        const newWeight = parseFloat(event.target.fish_weight.value);
        if(currentCatch.weight !== newWeight) {
            catchData['weight'] = newWeight;
        }

        if(currentCatch.length !== parseFloat(event.target.fish_length.value)) {
            catchData['length'] = parseFloat(event.target.fish_length.value);
        }
        if(currentCatch.info !== event.target.catch_info.value) {
            catchData['info'] = event.target.catch_info.value.replace(/[^0-9a-zA-Z!@%&-=+;:'",.? ]/g, '');
        }

        if(downloadedURL) {
            catchData['image_url'] = downloadedURL;
        }
                
        // Update catch in Firestore
        const catchRef = firebase.firestore().collection('catches').doc(currentCatch.id);
        try {
            await catchRef.update(catchData);
            toast.success('The catch has been updated successfully!');
            fetchAllCatchesLedger(); 
            setIsUpdating(false);
            setUpdatedImage(null);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating catch:", error);
            setIsUpdating(false);
            toast.error('Could not update catch entry. Please try again.');
        }
    };

    // Handle CSV file selection
    const handleCsvFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setCsvFile(event.target.files[0]);
        }
    };

    // Handle CSV Upload
    const handleUploadCSV = () => {
        if (!csvFile) {
            toast.error('No CSV file selected.');
            return;
        }
        setIsCsvUploading(true);
        setUploadProgress(0);

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: async function(results) {
                const data = results.data;
                if (data.length === 0) {
                    toast.error("CSV file is empty.");
                    setIsCsvUploading(false);
                    return;
                }

                let processedCount = 0;

                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    try {
                        await importCsvRow(row);
                        processedCount++;
                        setUploadProgress(Math.round((processedCount / data.length) * 100));
                        // Optional: You can comment out individual row toasts to reduce clutter
                        // toast.success(`Row ${i + 1}: Successfully imported.`);
                    } catch (err) {
                        console.error('Error importing row', row, err);
                        toast.error(`Row ${i + 1}: Failed to import.`);
                    }
                }

                toast.success(`CSV upload completed. Successfully imported ${processedCount} of ${data.length} rows.`);
                setIsCsvUploading(false);
                setCsvFile(null);
                toggleUploadCSVModal();
                fetchAllCatchesLedger();
            },
            error: function(err) {
                console.error("Error parsing CSV:", err);
                toast.error("Error parsing CSV file.");
                setIsCsvUploading(false);
            }
        });
    };

    // Import a single CSV row into Firestore (creating tag and catch)
    const importCsvRow = async (row) => {
        const firestore = firebase.firestore();

        // Extract fields from CSV
        const tagNumber = (row['tag_number'] || '').trim();
        const firstAnglerEmail = (row['first_angler_email'] || '').trim();
        const catchDateStr = row['catch_date'] || '';
        const fishColor = (row['fish_color'] || '').trim();
        const fishSpeciesId = (row['fish_species'] || '').trim();
        const imageUrl = (row['image_url'] || '').trim();
        const info = (row['info'] || '').trim();
        const length = parseFloat(row['length']) || 0;
        const locationLat = parseFloat(row['location_lat']) || 0;
        const locationLng = parseFloat(row['location_lng']) || 0;
        const locationArea = (row['location_area'] || '').trim();
        const locationCity = (row['location_city'] || '').trim();
        const locationCountry = (row['location_country'] || '').trim();
        const locationState = (row['location_state'] || '').trim();
        const locationStateCode = (row['location_state_code'] || '').trim();
        const locationString = (row['location_string'] || '').trim();
        const metricSystem = row['metric_system'] ? row['metric_system'].toLowerCase() === 'true' : false;
        const name = (row['name'] || '').trim();
        const recatch = row['recatch'] ? row['recatch'].toLowerCase() === 'true' : false;
        const speciesName = (row['species_name'] || '').trim();
        const userName = (row['user_name'] || '').trim();
        const weight = parseFloat(row['weight']) || 0;

        // Validate required fields
        if (!tagNumber) {
            throw new Error('Missing required field: tag_number');
        }
        if (!catchDateStr) {
            throw new Error('Missing required field: catch_date');
        }
        if (!fishSpeciesId) {
            throw new Error('Missing required field: fish_species');
        }
        if (!name) {
            throw new Error('Missing required field: name');
        }

        // Determine user reference
        let userRef = null;
        let userDoc = null;
        if (firstAnglerEmail) {
            // Fetch user by email (case-insensitive)
            const usersSnapshot = await firestore.collection('users')
                .where('email_address', '==', firstAnglerEmail.toLowerCase())
                .get();
            if (usersSnapshot.empty) {
                throw new Error(`User with email ${firstAnglerEmail} not found.`);
            }
            userDoc = usersSnapshot.docs[0];
            userRef = firestore.collection('users').doc(userDoc.id);
        } else {
            // Use logged-in user's details
            if (!userData.id) {
                throw new Error('Logged-in user data not found.');
            }
            userRef = firestore.collection('users').doc(userData.id);
            userDoc = await userRef.get();
            if (!userDoc.exists) {
                throw new Error('Logged-in user document does not exist.');
            }
        }

        // Fetch 'private' field from user document
        const userPrivate = userDoc.data().privacy_mode || false;

        // Create new tag
        const tagData = {
            tag_number: tagNumber,
            first_angler: userRef,
            catch_count: 0,
            created_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            updated_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            deleted: false,
            used: true,
            tag_program: firestore.collection('programs').doc(programData.id),
            program_image: programData.image_url || '',
            program_name: programData.program_name || '',
            private: userPrivate,
        };

        const tagRef = await firestore.collection('tags').add(tagData);

        // Handle image upload if provided
        let imageUrlFinal = '';
        if (imageUrl) {
            imageUrlFinal = imageUrl; // Assuming image_url is a valid URL provided in the CSV
        }

        // Create new catch
        const catchData = {
            catch_date: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            fish_color: fishColor || '',
            fish_species: firestore.collection('species').doc(fishSpeciesId),
            image_url: imageUrlFinal || '',
            info: info || '',
            length: length || 0,
            weight: weight || 0,
            location: new firebase.firestore.GeoPoint(locationLat, locationLng),
            location_area: locationArea || '',
            location_city: locationCity || '',
            location_country: locationCountry || '',
            location_state: locationState || '',
            location_state_code: locationStateCode || '',
            location_string: locationString || '',
            metric_system: metricSystem,
            name: name || '',
            recatch: recatch,
            species_name: speciesName || '',
            tag_program: firestore.collection('programs').doc(programData.id),
            tag: tagRef,
            tag_number: tagNumber,
            user: userRef,
            user_name: userName || (userDoc.data().first_name + ' ' + userDoc.data().last_name) || '',
            weight: weight || 0,
            deleted: false,
        };

        const catchRef = await firestore.collection('catches').add(catchData);

        // **New Code Starts Here: Updating Tag Document**
        try {
            await tagRef.update({
                latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef),
                latest_catches: firebase.firestore.FieldValue.arrayUnion(catchRef),
                updated_at: serverTimestamp(), // Update the 'updated_at' field
            });
        } catch (error) {
            console.error("Error updating tag document:", error);
            throw new Error('Failed to update tag with latest_anglers and latest_catches.');
        }
        // **New Code Ends Here**

        // Increment counts in user document
        const userUpdateData = {};
        if (recatch) {
            userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
        } else {
            userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(1);
        }
        userUpdateData['species_count'] = firebase.firestore.FieldValue.increment(1); // Assuming species_count is cumulative

        await userRef.update(userUpdateData);
    };

    // Handle CSV Download
    const handleDownloadCSV = async () => {
        try {
            const programRef = firebase.firestore().collection('programs').doc(programData.id);
            let csvQuery = firebase.firestore().collection('catches')
                .where('tag_program', '==', programRef)
                .orderBy('created_at', 'desc');

            if (selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species', '==', speciesRef);
            }
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (endDateFilter) {
                csvQuery = csvQuery
                    .where('catch_date', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];
            csvRows.push([
                'id',
                'name',
                'user_name',
                'location_string',
                'location_area',
                'location_city',
                'location_state',
                'location_state_code',
                'location_country',
                'location_lat',
                'location_lng',
                'tag_number',
                'catch_date',
                'recatch',
                'info',
                'image_url',
                'length',
                'weight',
                'metric_system',
                'species_name'
            ].join(','));

            snapshot.forEach((doc) => {
                const data = doc.data();
                const locLat = data.location ? data.location.latitude : '';
                const locLng = data.location ? data.location.longitude : '';

                const row = [
                    doc.id,
                    (data.name || '').replace(/,/g, ''), 
                    (data.user_name || '').replace(/,/g, ''),
                    (data.location_string || '').replace(/,/g, ''),
                    (data.location_area || '').replace(/,/g, ''),
                    (data.location_city || '').replace(/,/g, ''),
                    (data.location_state || '').replace(/,/g, ''),
                    (data.location_state_code || '').replace(/,/g, ''),
                    (data.location_country || '').replace(/,/g, ''),
                    locLat,
                    locLng,
                    (data.tag_number || '').replace(/,/g, ''),
                    data.catch_date
                        ? data.catch_date.toDate().toLocaleString()
                        : '',
                    data.recatch ? 'Yes' : 'No',
                    (data.info || '').replace(/,/g, ''),
                    (data.image_url || '').replace(/,/g, ''),
                    (data.length || '').toString().replace(/,/g, ''),
                    (data.weight || '').toString().replace(/,/g, ''),
                    typeof data.metric_system !== 'undefined' ? data.metric_system : '',
                    (data.species_name || '').replace(/,/g, '')
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'filtered_catches.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
            toast.success("CSV downloaded successfully.");
        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // Handle applying filters
    const handleApplyFilters = () => {
        fetchAllCatchesLedger();
        toast.info("Filters applied.");
    };

    // Delete catch functionality
    const deleteCatch = async () => {
        setIsDeletingCatch(true);
        
        const catchItem = allCatchesLedger[deleteCatchIndex];
        if (!catchItem) {
            console.error("Catch item not found for deletion.");
            setIsDeletingCatch(false);
            toast.error('Invalid catch data.');
            return;
        }

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        try {
            // Get all catches using the same tag
            const tagCatchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();
            const batch = firebase.firestore().batch();

            tagCatchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const userRef = usersRef.doc(catchData.user.id);
                const userDataUpdate = {
                    updated_at: serverTimestamp(),
                };
                if(catchData.recatch) {                        
                    userDataUpdate['recatch_count'] = catchData.recatch_count > 1 
                        ? firebase.firestore.FieldValue.increment(-1) 
                        : 0; 
                }  
                else {
                    userDataUpdate['catch_count'] = catchData.catch_count > 1 
                        ? firebase.firestore.FieldValue.increment(-1) 
                        : 0;
                }
                // Assuming species_count is cumulative per user
                if (catchData.fish_species) {
                    userDataUpdate['species_count'] = firebase.firestore.FieldValue.increment(-1);
                }
                batch.update(userRef, userDataUpdate);
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            // Commit the batch
            await batch.commit();

            toast.success('Deleted catch and related data successfully!');
            fetchAllCatchesLedger();
            setDeleteCatchModal(false);
            setDeleteRecatchModal(false);
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } catch (error) {
            console.error("Error deleting catch:", error);
            toast.error('Could not delete catch entry. Please try again.');
        } finally {
            setIsDeletingCatch(false);
        }
    };

    // Restore catch functionality
    const restoreCatch = async () => {
        setIsRestoringCatch(true);
        
        const catchItem = allCatchesLedger[restoreCatchIndex];
        if (!catchItem) {
            console.error("Catch item not found for restoration.");
            setIsRestoringCatch(false);
            toast.error('Invalid catch data.');
            return;
        }

        const usersRef = firebase.firestore().collection('users');
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchesRef = firebase.firestore().collection('catches');
        
        try {
            // Get all catches using the same tag
            const tagCatchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();
            const batch = firebase.firestore().batch();

            tagCatchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const userRef = usersRef.doc(catchData.user.id);
                const userDataUpdate = {
                    updated_at: serverTimestamp(),
                };
                if(catchData.recatch) {                        
                    userDataUpdate['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                }  
                else {
                    userDataUpdate['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }
                if (catchData.fish_species) {
                    userDataUpdate['species_count'] = firebase.firestore.FieldValue.increment(1);
                }
                batch.update(userRef, userDataUpdate);
                batch.update(catchesRef.doc(doc.id), {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            // Commit the batch
            await batch.commit();

            toast.success('Restored catch and related data successfully!');
            fetchAllCatchesLedger();
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } catch (error) {
            console.error("Error restoring catch:", error);
            toast.error('Could not restore catch entry. Please try again.');
        } finally {
            setIsRestoringCatch(false);
        }
    };

    // Delete recatch functionality
    const deleteRecatch = async () => {
        setIsDeletingRecatch(true);
        
        const catchItem = allCatchesLedger[deleteCatchIndex];
        if (!catchItem || !catchItem.recatch) {
            console.error("Re-Catch item not found for deletion.");
            setIsDeletingRecatch(false);
            toast.error('Invalid re-catch data.');
            return;
        }

        const userRef = firebase.firestore().collection('users').doc(catchItem.user.id);
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(deleteCatchId);  

        try {
            const batch = firebase.firestore().batch();

            // Update the catch document to deleted
            batch.update(catchRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                catch_count: firebase.firestore.FieldValue.increment(-1),
                latest_anglers: firebase.firestore.FieldValue.arrayRemove(userRef)
            });

            // Update the user document
            batch.update(userRef, {
                updated_at: serverTimestamp(),
                recatch_count: catchItem.recatch_count > 1 
                    ? firebase.firestore.FieldValue.increment(-1) 
                    : 0
            });

            // Commit the batch
            await batch.commit();

            toast.success('Deleted re-catch successfully!');
            fetchAllCatchesLedger();
            setDeleteCatchId('');
            setDeleteCatchIndex(null);
        } catch (error) {
            console.error("Error deleting re-catch:", error);
            toast.error('Could not delete re-catch entry. Please try again.');
        } finally {
            setIsDeletingRecatch(false);
        }
    };

    // Restore recatch functionality
    const restoreRecatch = async () => {
        setIsRestoringRecatch(true);
        
        const catchItem = allCatchesLedger[restoreCatchIndex];
        if (!catchItem || !catchItem.recatch) {
            console.error("Re-Catch item not found for restoration.");
            setIsRestoringRecatch(false);
            toast.error('Invalid re-catch data.');
            return;
        }

        const userRef = firebase.firestore().collection('users').doc(catchItem.user.id);
        const tagRef = firebase.firestore().collection('tags').doc(catchItem.tag.id);
        const catchRef = firebase.firestore().collection('catches').doc(restoreCatchId);  

        try {
            const batch = firebase.firestore().batch();

            // Update the catch document to not deleted
            batch.update(catchRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            // Update the tag document
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                catch_count: firebase.firestore.FieldValue.increment(1),
                latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef)
            });

            // Update the user document
            batch.update(userRef, {
                updated_at: serverTimestamp(),
                recatch_count: firebase.firestore.FieldValue.increment(1)
            });

            // Commit the batch
            await batch.commit();

            toast.success('Restored re-catch successfully!');
            fetchAllCatchesLedger();
            setRestoreCatchId('');
            setRestoreCatchIndex(null);
        } catch (error) {
            console.error("Error restoring re-catch:", error);
            toast.error('Could not restore re-catch entry. Please try again.');
        } finally {
            setIsRestoringRecatch(false);
        }
    };

    // Fetch data on initial load
    useEffect(() => {
        if(initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchTagPrograms();
            fetchAllCatchesLedger();
        }
    }, [initialLoad]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if(subscriptionRef.current) {
                subscriptionRef.current();
            }
        };
    }, []);

    // Derived data: filtered and searched catches
    const filteredCatches = useMemo(() => {
        return allCatchesLedger.filter(catchItem => {
            const matchesSearch = 
                catchItem.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.angler.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.location_string.toLowerCase().includes(searchQuery.toLowerCase()) ||
                catchItem.tag_number.toLowerCase().includes(searchQuery.toLowerCase());
            return matchesSearch;
        });
    }, [allCatchesLedger, searchQuery]);

    // Pagination logic
    const pageCount = Math.ceil(filteredCatches.length / catchesPerPage);
    const offset = currentPage * catchesPerPage;
    const currentCatches = filteredCatches.slice(offset, offset + catchesPerPage);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // FIND USER NAME BY ID
    const findNameById = (userId) => {
        // Assuming you have a list of users fetched and stored in allUsersList
        // Implement this function based on your actual data structure
        // For now, returning a placeholder
        return 'User Name'; // Replace with actual logic
    };

    // Handle creating new catch (manual entry)
    const handleCreateCatch = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const formData = new FormData(event.target);
        const catchNumber = formData.get('tag_number').trim();
        const firstAnglerEmail = formData.get('first_angler_email') ? formData.get('first_angler_email').trim() : '';
        const catchDateStr = formData.get('catch_date').trim();
        const fishColor = formData.get('fish_color').trim();
        const fishSpeciesId = formData.get('fish_species').trim();
        const imageFile = formData.get('image_file');
        const info = formData.get('info').trim();
        const length = parseFloat(formData.get('length')) || 0;
        const weight = parseFloat(formData.get('weight')) || 0;
        const locationLat = parseFloat(formData.get('location_lat')) || 0;
        const locationLng = parseFloat(formData.get('location_lng')) || 0;
        const locationArea = formData.get('location_area').trim();
        const locationCity = formData.get('location_city').trim();
        const locationCountry = formData.get('location_country').trim();
        const locationState = formData.get('location_state').trim();
        const locationStateCode = formData.get('location_state_code').trim();
        const locationString = formData.get('location_string').trim();
        const metricSystem = formData.get('metric_system') === 'true';
        const name = formData.get('name').trim();
        const recatch = formData.get('recatch') === 'true';
        const speciesName = formData.get('species_name').trim();
        const userName = formData.get('user_name').trim();

        try {
            const firestore = firebase.firestore();

            // Determine user reference
            let userRef = null;
            let userDoc = null;
            if (firstAnglerEmail) {
                // Fetch user by email (case-insensitive)
                const usersSnapshot = await firestore.collection('users')
                    .where('email_address', '==', firstAnglerEmail.toLowerCase())
                    .get();
                if (usersSnapshot.empty) {
                    throw new Error(`User with email ${firstAnglerEmail} not found.`);
                }
                userDoc = usersSnapshot.docs[0];
                userRef = firestore.collection('users').doc(userDoc.id);
            } else {
                // Use logged-in user's details
                if (!userData.id) {
                    throw new Error('Logged-in user data not found.');
                }
                userRef = firestore.collection('users').doc(userData.id);
                userDoc = await userRef.get();
                if (!userDoc.exists) {
                    throw new Error('Logged-in user document does not exist.');
                }
            }

            // Fetch 'private' field from user document
            const userPrivate = userDoc.data().privacy_mode || false;

            // Create new tag
            const tagData = {
                tag_number: catchNumber,
                first_angler: userRef,
                catch_count: 0,
                created_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
                updated_at: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
                deleted: false,
                used: true,
                tag_program: firestore.collection('programs').doc(programData.id),
                program_image: programData.image_url || '',
                program_name: programData.program_name || '',
                private: userPrivate,
            };

            const tagRef = await firestore.collection('tags').add(tagData);

            // Handle image upload if provided
            let imageUrl = '';
            if (imageFile && imageFile.size > 0) {
                const imageFileName = `/catch-images/${catchNumber}-${userDoc.id}-${Date.now()}`;
                const storageRefObj = ref(storage, imageFileName);
            
                const uploadTask = uploadBytesResumable(storageRefObj, imageFile);
                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            setUploadProgress(progress);
                        },
                        (error) => {
                            console.error("Error uploading image:", error);
                            reject(error);
                        },
                        async () => {
                            try {
                                imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                                resolve();
                            } catch (err) {
                                console.error("Error getting download URL:", err);
                                reject(err);
                            }
                        }
                    );
                });
            }

            // Create new catch
            const catchData = {
                catch_date: catchDateStr ? firebase.firestore.Timestamp.fromDate(new Date(catchDateStr)) : serverTimestamp(),
                created_at: serverTimestamp(),
                updated_at: serverTimestamp(),
                fish_color: fishColor || '',
                fish_species: firestore.collection('species').doc(fishSpeciesId),
                image_url: imageUrl || '',
                info: info || '',
                length: length || 0,
                weight: weight || 0,
                location: new firebase.firestore.GeoPoint(locationLat, locationLng),
                location_area: locationArea || '',
                location_city: locationCity || '',
                location_country: locationCountry || '',
                location_state: locationState || '',
                location_state_code: locationStateCode || '',
                location_string: locationString || '',
                metric_system: metricSystem,
                name: name || '',
                recatch: recatch,
                species_name: speciesName,
                tag_program: firestore.collection('programs').doc(programData.id),
                tag: tagRef,
                user: userRef,
                user_name: userName || (userDoc.data().first_name + ' ' + userDoc.data().last_name) || '',
                weight: weight || 0,
                deleted: false,
            };

            const catchRef = await firestore.collection('catches').add(catchData);

            // **New Code Starts Here: Updating Tag Document**
            try {
                await tagRef.update({
                    latest_anglers: firebase.firestore.FieldValue.arrayUnion(userRef),
                    latest_catches: firebase.firestore.FieldValue.arrayUnion(catchRef),
                    updated_at: serverTimestamp(), // Update the 'updated_at' field
                });
            } catch (error) {
                console.error("Error updating tag document:", error);
                throw new Error('Failed to update tag with latest_anglers and latest_catches.');
            }
            // **New Code Ends Here**

            // Increment counts in user document
            const userUpdateData = {};
            if (recatch) {
                userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
            } else {
                userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(1);
            }
            userUpdateData['species_count'] = firebase.firestore.FieldValue.increment(1); // Assuming species_count is cumulative

            await userRef.update(userUpdateData);

            toast.success(`Catch "${name}" imported successfully.`);
            fetchAllCatchesLedger();
            setIsUploading(false);
            toggleModalList();
        } catch (error) {
            console.error("Error importing catch:", error);
            toast.error(`Error importing catch: ${error.message}`);
            setIsUploading(false);
        }
    };

    // Handle CSV Upload Modal Toggle
    const toggleUploadCSVModal = () => {
        setCsvFile(null);
        setUploadProgress(0);
        setUploadCSVModal(!uploadCSVModal);
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle>Catch Ledger</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">All Catches</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="catch-list">
                                        <Row className="g-4 mb-3">
                                            {/* Add Catch Button */}
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button 
                                                        color="success" 
                                                        onClick={toggleModalList}
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Add Catch
                                                    </Button>
                                                </div>
                                            </Col>
                                            {/* Search Box */}
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-start">
                                                    <div className="search-box ms-2">
                                                        <Input 
                                                            type="search" 
                                                            className="form-control" 
                                                            placeholder="Search Catches..." 
                                                            value={searchQuery}
                                                            onChange={(e) => setSearchQuery(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            {/* NEW: Species Filter */}
                                            <Col sm="2" md="2">
                                                <select 
                                                    className="form-control" 
                                                    value={selectedSpeciesFilter}
                                                    onChange={(e) => setSelectedSpeciesFilter(e.target.value)}
                                                >
                                                    <option value="">All Species</option>
                                                    {allSpecies.map((spec) => (
                                                        <option key={spec.id} value={spec.id}>
                                                            {spec.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Col>

                                            {/* NEW: Start Date */}
                                            <Col sm="2" md="2">
                                                <Flatpickr
                                                    className="form-control"
                                                    value={startDateFilter}
                                                    onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                                    options={{ 
                                                        dateFormat: "Y-m-d",
                                                        maxDate: endDateFilter ? endDateFilter : undefined
                                                    }}
                                                    placeholder="Start Date"
                                                />
                                            </Col>

                                            {/* NEW: End Date */}
                                            <Col sm="2" md="2">
                                                <Flatpickr
                                                    className="form-control"
                                                    value={endDateFilter}
                                                    onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                                    options={{ 
                                                        dateFormat: "Y-m-d",
                                                        minDate: startDateFilter ? startDateFilter : undefined
                                                    }}
                                                    placeholder="End Date"
                                                />
                                            </Col>

                                            {/* Apply Filters Button */}
                                            <Col sm="1" md="1" className="d-flex align-items-end">
                                                <Button 
                                                    color="secondary" 
                                                    onClick={handleApplyFilters}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-filter"></i>
                                                </Button>
                                            </Col>

                                            {/* Download CSV Button */}
                                            <Col sm="1" md="1" className="d-flex align-items-end">
                                                <Button 
                                                    color="primary" 
                                                    onClick={handleDownloadCSV}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-download"></i>
                                                </Button>
                                            </Col>

                                            {/* Upload CSV Button */}
                                            <Col sm="1" md="1" className="d-flex align-items-end">
                                                <Button
                                                    color="info"
                                                    onClick={toggleUploadCSVModal}
                                                    style={{ width: '100%' }}
                                                >
                                                    <i className="mdi mdi-upload"></i>
                                                </Button>
                                            </Col>
                                        </Row>

                                        {/* Catches Table */}
                                        <div className="table-responsive table-card mt-3 mb-1">
                                            {(!isUpdating && !isDeletingRecatch && !isDeletingCatch && !isRestoringRecatch && !isCsvUploading && !isUploading) && (
                                                <table className="table align-middle table-nowrap" id="catch-table">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col" style={{ width: "50px" }}>View</th>
                                                            <th className="sort" data-sort="image_url">Image</th>
                                                            <th className="sort" data-sort="name">Catch Name</th>
                                                            <th className="sort" data-sort="angler">Angler Name</th>
                                                            <th className="sort" data-sort="location_string">Catch Location</th>
                                                            <th className="sort" data-sort="tag_number">Tag Number</th>
                                                            <th className="sort" data-sort="date" data-default-order='desc'>Catch Date</th>
                                                            <th className="sort" data-sort="type">Type</th>
                                                            <th className="sort" data-sort="action">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {currentCatches.length > 0 ? (
                                                            currentCatches.map((catchItem) => ( 
                                                                <tr key={catchItem.id}>
                                                                    <th scope="row">
                                                                        <Link className="btn btn-sm btn-success edit-item-btn" to={catchItem.link}>
                                                                            <i className="mdi mdi-eye-outline"></i>
                                                                        </Link>
                                                                    </th>
                                                                    <td className="image_url">
                                                                        {catchItem.image_url && (
                                                                            <img className="avatar-sm rounded" alt={catchItem.name} src={catchItem.image_url} />
                                                                        )}
                                                                    </td>
                                                                    <td className="name">{catchItem.name}</td>
                                                                    <td>
                                                                        <Link to={catchItem.user_link} className="angler fw-medium link-primary">
                                                                            {catchItem.angler}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="location_string">{catchItem.location_string}</td>
                                                                    <td>
                                                                        <Link to={catchItem.tag_link} className="tag_number fw-medium link-primary">
                                                                            #{catchItem.tag_number}
                                                                        </Link>
                                                                    </td>
                                                                    <td className="date">{catchItem.date}</td>
                                                                    <td className="type">
                                                                        {catchItem.recatch ? (
                                                                            <span className="badge badge-soft-warning text-uppercase">Recatch</span>
                                                                        ) : (
                                                                            <span className="badge badge-soft-success text-uppercase">Catch</span>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex gap-2">
                                                                            <div className="edit">
                                                                                <Button
                                                                                    color="warning"
                                                                                    size="sm"
                                                                                    onClick={() => toggleEditModal(catchItem.id, allCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                >
                                                                                    <i className="mdi mdi-square-edit-outline"></i>
                                                                                </Button>
                                                                            </div>
                                                                            {catchItem.recatch && !catchItem.deleted && (
                                                                                <div className="remove">
                                                                                    <Button 
                                                                                        color="danger"
                                                                                        size="sm"
                                                                                        onClick={() => toggleDeleteRecatchModal(catchItem.id, allCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                    >
                                                                                        <i className="mdi mdi-delete-outline"></i>
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {!catchItem.recatch && !catchItem.deleted && (
                                                                                <div className="remove">
                                                                                    <Button 
                                                                                        color="danger"
                                                                                        size="sm"
                                                                                        onClick={() => toggleDeleteCatchModal(catchItem.id, allCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                    >
                                                                                        <i className="mdi mdi-delete-outline"></i>
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            
                                                                            {catchItem.recatch && catchItem.deleted && (
                                                                                <div className="remove">
                                                                                    <Button 
                                                                                        color="success"
                                                                                        size="sm"
                                                                                        onClick={() => toggleRestoreRecatchModal(catchItem.id, allCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                    >
                                                                                        <i className="mdi mdi-history"></i>
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                            {!catchItem.recatch && catchItem.deleted && (
                                                                                <div className="remove">
                                                                                    <Button 
                                                                                        color="success"
                                                                                        size="sm"
                                                                                        onClick={() => toggleRestoreCatchModal(catchItem.id, allCatchesLedger.findIndex(item => item.id === catchItem.id))}
                                                                                    >
                                                                                        <i className="mdi mdi-history"></i>
                                                                                    </Button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="9" className="text-center">No Catches Found!</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}
                                            
                                            {filteredCatches.length === 0 && (
                                                <div className="noresult">
                                                    <div className="text-center">
                                                        <h5 className="mt-2">No Catches Found!</h5>
                                                        <p className="text-muted mb-0">We could not find any catches.</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {/* Pagination Controls */}
                                        {pageCount > 1 && (
                                            <div className="d-flex justify-content-end">
                                                <ReactPaginate
                                                    previousLabel={"Previous"}
                                                    nextLabel={"Next"}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={pageCount}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={handlePageClick}
                                                    containerClassName={"pagination justify-content-center"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Catch Modal */}
            <Modal isOpen={editModal} toggle={toggleEditModal} centered>
                <form className="tablelist-form" onSubmit={submitUpdateForm}>
                    <ModalHeader toggle={toggleEditModal}>Edit Catch</ModalHeader>
                    <ModalBody>
                        {!isUpdating && allCatchesLedger[updateCatchIndex] && (
                            <>
                                <FormGroup>
                                    <Label for="name-field">Catch Name*</Label>
                                    <Input 
                                        type="text" 
                                        name="catch_name" 
                                        id="name-field" 
                                        className="form-control" 
                                        placeholder="Enter Name" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].name}
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="catch_datetime">Catch Date*</Label>
                                    <Flatpickr
                                        className="form-control"
                                        name="catch_datetime"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d H:i",
                                            defaultDate: allCatchesLedger[updateCatchIndex].catch_date.toDate()
                                        }}
                                        placeholder="Select Date & Time"
                                        required
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="image-field">Image File</Label>
                                    <div className="d-flex align-items-center">
                                        {allCatchesLedger[updateCatchIndex].image_url && (
                                            <img 
                                                className="avatar-sm rounded me-3" 
                                                alt={allCatchesLedger[updateCatchIndex].name} 
                                                src={allCatchesLedger[updateCatchIndex].image_url} 
                                            />
                                        )}
                                        <Input 
                                            type="file" 
                                            name="image_file" 
                                            id="image-field" 
                                            className="form-control" 
                                            onChange={(e) => { setUpdatedImage(e.target.files[0]) }} 
                                            accept="image/*"
                                        />
                                    </div>
                                </FormGroup>

                                {/* Additional Form Fields */}
                                <FormGroup>
                                    <Label for="location_string">Full Catch Location*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_string" 
                                        className="form-control" 
                                        name="location_string" 
                                        placeholder="Toms River" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_string}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="catch_latitude">Latitude*</Label>
                                    <Input 
                                        type="number" 
                                        id="catch_latitude" 
                                        className="form-control" 
                                        name="catch_latitude" 
                                        placeholder="Enter Latitude*" 
                                        step="any" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location.latitude} 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="catch_longitude">Longitude*</Label>
                                    <Input 
                                        type="number" 
                                        id="catch_longitude" 
                                        className="form-control" 
                                        name="catch_longitude" 
                                        placeholder="Enter Longitude*" 
                                        step="any" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location.longitude} 
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="location_area">Catch Location Area*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_area" 
                                        className="form-control" 
                                        name="location_area" 
                                        placeholder="Enter Location Area*" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_area}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location_city">Catch Location City*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_city" 
                                        className="form-control" 
                                        name="location_city" 
                                        placeholder="Atlantic City" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_city}
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="state_string">Catch State*</Label>
                                    <Input 
                                        type="text" 
                                        id="state_string" 
                                        className="form-control" 
                                        name="state_string" 
                                        placeholder="New York" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_state}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="state_code">State Code*</Label>
                                    <Input 
                                        type="text" 
                                        id="state_code" 
                                        className="form-control" 
                                        name="state_code" 
                                        placeholder="NY" 
                                        required 
                                        maxLength={2} 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_state_code}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="catch_country">Catch Country*</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        id="catch_country" 
                                        name="catch_country" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].location_country}
                                    >
                                        <option value="">Select Catch Country*</option>
                                        <option value="USA">United States</option>
                                        <option value="CAN">Canada</option>
                                        {/* Add more countries as needed */}
                                    </Input>
                                </FormGroup>
                    
                                <FormGroup>
                                    <Label for="tag_number">Tag Number*</Label>
                                    <Input 
                                        type="text" 
                                        id="tag_number" 
                                        name="tag_number" 
                                        className="form-control" 
                                        placeholder="Enter Tag Number*" 
                                        maxLength={15} 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].tag_number} 
                                    />
                                </FormGroup>
                    
                                <FormGroup>
                                    <Label for="fish_species">Fish Species*</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        name="fish_species" 
                                        id="fish_species" 
                                        required 
                                        defaultValue={allCatchesLedger[updateCatchIndex].fish_species.id}
                                    >
                                        <option value="">Select Fish Species*</option>
                                        {allSpecies.map(({ name, id }) => (
                                            <option key={id} value={id}>{name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>   
                                
                                <FormGroup>
                                    <Label for="fish_length">Fish Length ({allCatchesLedger[updateCatchIndex].metric_system ? 'CM' : 'IN'})</Label>
                                    <Input 
                                        type="number" 
                                        id="fish_length" 
                                        className="form-control" 
                                        placeholder={`Enter Fish Length (${allCatchesLedger[updateCatchIndex].metric_system ? 'CM' : 'IN'})`} 
                                        name="fish_length"
                                        step="any"
                                        defaultValue={allCatchesLedger[updateCatchIndex].length} 
                                        required
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="fish_weight">Fish Weight ({allCatchesLedger[updateCatchIndex].metric_system ? 'KG' : 'LB'})</Label>
                                    <Input 
                                        type="number" 
                                        id="fish_weight" 
                                        className="form-control" 
                                        placeholder={`Enter Fish Weight (${allCatchesLedger[updateCatchIndex].metric_system ? 'KG' : 'LB'})`} 
                                        name="fish_weight"
                                        step="any"
                                        defaultValue={allCatchesLedger[updateCatchIndex].weight} 
                                        required
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="catch_info">Catch Information</Label>
                                    <Input 
                                        type="text" 
                                        id="catch_info" 
                                        className="form-control" 
                                        placeholder="Catch Information" 
                                        defaultValue={allCatchesLedger[updateCatchIndex].info} 
                                        name="catch_info"
                                    />
                                </FormGroup>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button type="button" color="secondary" onClick={toggleEditModal}>Cancel</Button>
                            <Button type="submit" color="success">Update</Button>
                        </div>
                    </ModalFooter>
                </form>
                {isUpdating && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Add Catch Modal */}
            <Modal isOpen={modalList} toggle={toggleModalList} centered>
                <form className="tablelist-form" onSubmit={handleCreateCatch}>
                    <ModalHeader toggle={toggleModalList}>Add Catch</ModalHeader>
                    <ModalBody>
                        {!isUploading && (
                            <>
                                <FormGroup>
                                    <Label for="name-field">Catch Name*</Label>
                                    <Input 
                                        type="text" 
                                        name="name" 
                                        id="name-field" 
                                        className="form-control" 
                                        placeholder="Enter Name" 
                                        required 
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="catch_datetime">Catch Date*</Label>
                                    <Flatpickr
                                        className="form-control"
                                        name="catch_date"
                                        options={{
                                            enableTime: true,
                                            dateFormat: "Y-m-d H:i",
                                            defaultDate: new Date()
                                        }}
                                        placeholder="Select Date & Time"
                                        required
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location_lat">Latitude*</Label>
                                    <Input 
                                        type="number" 
                                        id="location_lat" 
                                        className="form-control" 
                                        name="location_lat" 
                                        placeholder="Enter Latitude*" 
                                        step="any" 
                                        required 
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="location_lng">Longitude*</Label>
                                    <Input 
                                        type="number" 
                                        id="location_lng" 
                                        className="form-control" 
                                        name="location_lng" 
                                        placeholder="Enter Longitude*" 
                                        step="any" 
                                        required 
                                    />
                                </FormGroup>
                    
                                <FormGroup>
                                    <Label for="tag_number">Tag Number*</Label>
                                    <Input 
                                        type="text" 
                                        id="tag_number" 
                                        name="tag_number" 
                                        className="form-control" 
                                        placeholder="Enter Tag Number*" 
                                        maxLength={15} 
                                        required 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="first_angler_email">First Angler Email</Label>
                                    <Input 
                                        type="email" 
                                        id="first_angler_email" 
                                        className="form-control" 
                                        name="first_angler_email" 
                                        placeholder="Enter Angler Email (Optional)" 
                                    />
                                </FormGroup>
                
                                <FormGroup>
                                    <Label for="fish_color">Fish Color</Label>
                                    <Input 
                                        type="text" 
                                        id="fish_color" 
                                        className="form-control" 
                                        name="fish_color" 
                                        placeholder="Enter Fish Color (Optional)" 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="fish_species">Fish Species*</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        name="fish_species" 
                                        id="fish_species" 
                                        required 
                                    >
                                        <option value="">Select Fish Species*</option>
                                        {allSpecies.map(({ name, id }) => (
                                            <option key={id} value={id}>{name}</option>
                                        ))}
                                    </Input>
                                </FormGroup>   
                                
                                <FormGroup>
                                    <Label for="image_file">Image File</Label>
                                    <Input 
                                        type="file" 
                                        name="image_file" 
                                        id="image_file" 
                                        className="form-control" 
                                        accept="image/*"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="info">Info</Label>
                                    <Input 
                                        type="text" 
                                        id="info" 
                                        className="form-control" 
                                        name="info" 
                                        placeholder="Enter Info (Optional)" 
                                    />
                                </FormGroup>
                
                                <FormGroup>
                                    <Label for="length">Length ({userData.metric_system ? 'CM' : 'IN'})</Label>
                                    <Input 
                                        type="number" 
                                        id="length" 
                                        className="form-control" 
                                        name="length" 
                                        placeholder={`Enter Length (${userData.metric_system ? 'CM' : 'IN'})`} 
                                        step="any" 
                                        required 
                                    />
                                </FormGroup>
                
                                <FormGroup>
                                    <Label for="weight">Weight ({userData.metric_system ? 'KG' : 'LB'})</Label>
                                    <Input 
                                        type="number" 
                                        id="weight" 
                                        className="form-control" 
                                        name="weight" 
                                        placeholder={`Enter Weight (${userData.metric_system ? 'KG' : 'LB'})`} 
                                        step="any" 
                                        required 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location_area">Catch Location Area*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_area" 
                                        className="form-control" 
                                        name="location_area" 
                                        placeholder="Enter Location Area*" 
                                        required 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location_city">Catch Location City*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_city" 
                                        className="form-control" 
                                        name="location_city" 
                                        placeholder="Enter Location City*" 
                                        required 
                                    />
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="location_state">Catch State*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_state" 
                                        className="form-control" 
                                        name="location_state" 
                                        placeholder="Enter State*" 
                                        required 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location_state_code">State Code*</Label>
                                    <Input 
                                        type="text" 
                                        id="location_state_code" 
                                        className="form-control" 
                                        name="location_state_code" 
                                        placeholder="Enter State Code*" 
                                        maxLength={2} 
                                        required 
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="catch_country">Catch Country*</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        name="catch_country" 
                                        id="catch_country" 
                                        required 
                                    >
                                        <option value="">Select Catch Country*</option>
                                        <option value="USA">United States</option>
                                        <option value="CAN">Canada</option>
                                        {/* Add more countries as needed */}
                                    </Input>
                                </FormGroup>
                    
                                <FormGroup>
                                    <Label for="species_name">Species Name</Label>
                                    <Input 
                                        type="text" 
                                        id="species_name" 
                                        className="form-control" 
                                        name="species_name" 
                                        placeholder="Enter Species Name (Optional)" 
                                    />
                                </FormGroup>
                    
                                <FormGroup>
                                    <Label for="recatch">Re-Catch</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        name="recatch" 
                                        id="recatch" 
                                        required 
                                        defaultValue="false"
                                    >
                                        <option value="false">No</option>
                                        <option value="true">Yes</option>
                                    </Input>
                                </FormGroup>
                                
                                <FormGroup>
                                    <Label for="metric_system">Metric System</Label>
                                    <Input 
                                        type="select" 
                                        className="form-control" 
                                        name="metric_system" 
                                        id="metric_system" 
                                        required 
                                        defaultValue={userData.metric_system ? 'true' : 'false'}
                                    >
                                        <option value="true">Metric</option>
                                        <option value="false">Imperial</option>
                                    </Input>
                                </FormGroup>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <Button type="button" color="secondary" onClick={toggleModalList}>Cancel</Button>
                            <Button type="submit" color="success" disabled={isUploading}>Add Catch</Button>
                        </div>
                    </ModalFooter>
                </form>
                {isUploading && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Delete Re-Catch Modal */}
            <Modal isOpen={deleteRecatchModal} toggle={() => { toggleDeleteRecatchModal(); }} centered>
                {!isDeletingRecatch && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Re-Catch Data</h4>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to delete this re-catch?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button type="button" color="light" onClick={() => toggleDeleteRecatchModal()}>Cancel</Button>
                            <Button type="button" color="danger" onClick={deleteRecatch}>Delete</Button>
                        </div>
                    </ModalBody>
                )}
                {isDeletingRecatch && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeletingRecatch}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
            
            {/* Delete Catch Modal */}
            <Modal isOpen={deleteCatchModal} toggle={() => { toggleDeleteCatchModal(); }} centered>
                {!isDeletingCatch && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Catch Data</h4>
                                <p className="text-muted mx-4 mb-0">
                                    This will delete the catch, connected re-catches, and the fish-tag it used!
                                </p>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to delete this catch?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button type="button" color="light" onClick={() => toggleDeleteCatchModal()}>Cancel</Button>
                            <Button type="button" color="danger" onClick={deleteCatch}>Delete</Button>
                        </div>
                    </ModalBody>
                )}
                {isDeletingCatch && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeletingCatch}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
            
            {/* Restore Re-Catch Modal */}
            <Modal isOpen={restoreRecatchModal} toggle={() => { toggleRestoreRecatchModal(); }} centered>
                {!isRestoringRecatch && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Re-Catch Data</h4>
                                <p className="text-muted mx-4 mb-0">Do you want to restore this re-catch?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button type="button" color="light" onClick={() => toggleRestoreRecatchModal()}>Cancel</Button>
                            <Button type="button" color="success" onClick={restoreRecatch}>Restore</Button>
                        </div>
                    </ModalBody>
                )}
                {isRestoringRecatch && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringRecatch}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
            
            {/* Restore Catch Modal */}
            <Modal isOpen={restoreCatchModal} toggle={() => { toggleRestoreCatchModal(); }} centered>
                {!isRestoringCatch && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Catch Data</h4>
                                <p className="text-muted mx-4 mb-0">
                                    This will restore the catch, connected re-catches, and the fish-tag it used!
                                </p>
                                <p className="text-muted mx-4 mb-0">Are you sure you want to restore this catch?</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button type="button" color="light" onClick={() => toggleRestoreCatchModal()}>Cancel</Button>
                            <Button type="button" color="success" onClick={restoreCatch}>Restore</Button>
                        </div>
                    </ModalBody>
                )}
                {isRestoringCatch && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringCatch}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Upload CSV Modal */}
            <Modal isOpen={uploadCSVModal} toggle={toggleUploadCSVModal} centered>
                <form className="tablelist-form" onSubmit={(e) => { e.preventDefault(); handleUploadCSV(); }}>
                    <ModalHeader toggle={toggleUploadCSVModal}>Import Catches from CSV</ModalHeader>
                    <ModalBody>
                        {!isCsvUploading && (
                            <>
                                <FormGroup>
                                    <Label for="csvFile">Select CSV File</Label>
                                    <Input
                                        type="file"
                                        name="csvFile"
                                        id="csvFile"
                                        accept=".csv"
                                        required
                                        onChange={handleCsvFileChange}
                                    />
                                </FormGroup>
                                <p className="text-muted">
                                    The CSV file should contain the following columns:
                                    <ul>
                                        <li><strong>tag_number</strong> (String)</li>
                                        <li><strong>first_angler_email</strong> (String - User's Email Address, Optional)</li>
                                        <li><strong>catch_date</strong> (String - e.g., "2023-10-15 14:30")</li>
                                        <li><strong>fish_color</strong> (String, Optional)</li>
                                        <li><strong>fish_species</strong> (String - ID of the Fish Species)</li>
                                        <li><strong>image_url</strong> (String, Optional)</li>
                                        <li><strong>info</strong> (String, Optional)</li>
                                        <li><strong>length</strong> (Number)</li>
                                        <li><strong>weight</strong> (Number)</li>
                                        <li><strong>location_lat</strong> (Number)</li>
                                        <li><strong>location_lng</strong> (Number)</li>
                                        <li><strong>location_area</strong> (String)</li>
                                        <li><strong>location_city</strong> (String)</li>
                                        <li><strong>location_state</strong> (String)</li>
                                        <li><strong>location_state_code</strong> (String)</li>
                                        <li><strong>location_country</strong> (String)</li>
                                        <li><strong>location_string</strong> (String)</li>
                                        <li><strong>metric_system</strong> (Boolean - "true" or "false")</li>
                                        <li><strong>name</strong> (String)</li>
                                        <li><strong>recatch</strong> (Boolean - "true" or "false")</li>
                                        <li><strong>species_name</strong> (String, Optional)</li>
                                        <li><strong>user_name</strong> (String, Optional)</li>
                                    </ul>
                                </p>
                            </>
                        )}
                        {isCsvUploading && (
                            <div className="d-flex flex-column align-items-center">
                                <ThreeCircles
                                    visible={isCsvUploading}
                                    height="100"
                                    width="100"
                                    color="#4192C3"
                                    ariaLabel="three-circles-loading"
                                    wrapperStyle={{ margin: "0 auto" }}
                                    wrapperClass="justify-content-center"
                                />
                                <p className="mt-3">Uploading CSV and processing data...</p>
                                <div className="w-100 mt-3">
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: `${uploadProgress}%` }}
                                            aria-valuenow={uploadProgress}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {uploadProgress}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    {!isCsvUploading && (
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button type="button" color="secondary" onClick={toggleUploadCSVModal}>Cancel</Button>
                                <Button type="submit" color="primary">Upload CSV</Button>
                            </div>
                        </ModalFooter>
                    )}
                </form>
            </Modal>

            {/* Toast Container for Notifications */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </React.Fragment>
    );

};

export default AllCatches;